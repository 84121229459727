export default function Alert() {
    return (
        <div>
            <div className="mdui-prose">
                <h2>回应恶意抹黑</h2>
                <p>在最近的半个月里，L0veL社区（以下称为“本服”或“L0”）一直在遭受DDoS攻击和恶意抹黑，造成了数万元的经济损失和不可估量的名誉损失。</p>
                <p>QQ阿甘（自称是人尽皆知的Big
                    Name，自称其真名为陈钰林（其真名从其本人口中得知（其在其QQ群内多次称自己名为陈玉林，刚刚购买了一辆奔驰A（后又称其购买的是奔驰G，但QQ空间晒图为奔驰A动感型）），此处公开仅为读者更好分辨谁是恶意DDoS攻击者））对本服务器发起DDoS攻击的证据确凿（QQ阿甘已承认与前花满楼公会会长共同谋划DDoS攻击本服务器且相关聊天记录已披露）</p>
                <img alt="agandfu" src="/img/alert/agand1.jpg"/>
                <img alt="agandfu" src="/img/alert/agand2.jpg"/>
                <p>从上方聊天记录可以了解到，阿甘至少在2020年就开始在互联网上肆意DDoS，并且在此事件中与Sviper共同谋划DDoS本服。</p>
                <p>至于Sviper一直在互联网上造谣本服服主为黑社会团队更是无稽之谈。</p>
                <p>Sviper本人在担任花满楼公会会长一职期间，多次要挟本服管理团队，如不给予其群内管理员职位，便要求公会会员不再游玩本服务器，同时在群内多次挑衅群管理，发表不尊重言论，同时被群内成员指认与辱华玩家勾结一致辱华。</p>
                <img alt="qun" src="/img/alert/qun.png"/>
                <p>Sviper不仅与辱华玩家勾结游戏，更与境外黑产深度绑定，QQ个人签名也指向其在境外网站的个人主页，有为境外黑色产业引流之嫌。其在将本服群内管理悉数人肉后，更是利用腾讯QQ平台漏洞将本服QQ群恶意封禁。</p>
                <p>Sviper更放出狂言称，其家族拥有人脉关系，能够为其在境外网络上勾结黑产提供保护伞，Sviper更声称其父亲是编制内人员，拥有他人无法比拟的人脉，就算其勾结黑产的行径暴露也不会出事。</p>
                <img alt="zhengjv" src="/img/alert/zhengjv.png"/>
                <p>上图展示了Sviper本人勾结境外黑产的部分证据。</p>
                <img alt="gongji" src="/img/alert/gongji.png"/>
                <p>仅仅是因为本服不在群内将Sviper设为管理，Sviper便使用境外僵尸网络对本服的官网发起了DDoS攻击，在五分钟内发送了超过两千万次恶意请求，并将其外网平台署名置于请求特征处，这次攻击可见Sviper与境外非法势力勾结之深。</p>
                <p>总结，在此次事件中，被人肉搜索至退网、被黑产团队使用平台漏洞封禁QQ群组、被网络水军开团造谣以及被DDoS攻击半个月之久的一直是本服，本服一直处于受害者的位置却要被污蔑为网络黑社会团队。一切对本服抹黑造谣的言论皆为无稽之谈，本服保留对非法行为追究责任的权利，互联网不是法外之地，望与本事无关之人员擦亮双眼，不要被境外势力所蒙骗。</p>
            </div>
        </div>
    );
}
