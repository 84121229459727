import { useAudioPlayer } from "./AudioProvider";

export default function AudioPlayButton({ setIsAudioPlayerShowed, audio, playing, paused, ...props }) {
    let player = useAudioPlayer(audio);

    return (
        <button
            type="button"
            onClick={() => {
                player.toggle();
                setIsAudioPlayerShowed(true);
            }}
            aria-label={`${player.playing ? 'Pause' : 'Play'} audio ${
                audio.url
            }`}
            {...props}
        >
            {player.playing ? playing : paused}
        </button>
    )
}
