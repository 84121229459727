export default function Group() {
    return (
        <div>
            <div className="mdui-prose">
                <h2>联系我们</h2>
                <p>bocchi@ukr.net</p>
                <p>官方QQ交流群: 123456</p>
                <p>服主联系QQ: 630154839</p>
            </div>
        </div>
    );
}
