import {useState, useEffect, useContext, useRef} from "react";
import { useLocalStorage } from "react-use";
import { useNavigate } from "react-router-dom";
import { fetchLeaderboard, fetchGuildLeaderboard, fetchLeaderBoardInfo } from "../api/auth";
import { snackbar } from "mdui/functions/snackbar.js";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { AudioPlayerContext } from "../App";
import AudioPlayButton from "../components/AudioPlayButton";
import { apiUrl } from "../api/constant";
import { PauseIcon, MusicalNoteIcon } from "@heroicons/react/24/solid";
import {CheckBadgeIcon, BoltIcon} from "@heroicons/react/20/solid";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function VerifyTag({ item }) {
    const navigate = useNavigate();
    return <mdui-tooltip
        variant="rich"
        headline={"L0veL Verify - " + (
            item.verifyType === 'player' ? '玩家认证' :
                item.verifyType === 'official' ? '组织认证' : ''
        )}
        placement="top"
        class="group"
        content={
            item.verifyType === 'player' ? '该玩家已被认证为于L0veL游玩的知名玩家，认证仅用于识别该玩家的身份，L0veL仅提供身份标识服务且不对其他事项负责(玩家个人行为等)，点击认证标志查看更多详情' :
                item.verifyType === 'official' ? '该玩家已被认证为与L0veL相关联的或经L0veL验证的组织的所有者或联合创始人(经验证的管理员等)，L0veL已验证此玩家的身份和组织真实性，点击认证标志查看更多详情' : ''
        }
    >
        <div onClick={() => navigate('/verify')} className="py-1 cursor-pointer">
            <div
                className={`rounded-full p-0.5 ${
                    item.verifyType === 'player' ? 'group-hover:bg-amber-100' :
                        item.verifyType === 'official' ? 'group-hover:bg-blue-100' : ''
                } flex flex-row items-center transition-all duration-500 ease-in-out group-hover:space-x-[0.085rem] group-hover:pr-1.5`}>
                {
                    item.verifyType === 'player' ?
                        <BoltIcon className="h-5 w-5 text-amber-400"
                                  aria-hidden="true"/> :
                        item.verifyType === 'official' ? <CheckBadgeIcon
                                className="h-[1.4rem] w-[1.4rem] text-blue-500"
                                aria-hidden="true"/> :
                            null
                }
                <span
                    className={`text-sm ${
                        item.verifyType === 'player' ? 'text-amber-400' :
                            item.verifyType === 'official' ? 'text-blue-500' : ''
                    } whitespace-nowrap overflow-hidden max-w-0 group-hover:max-w-full transition-all duration-500 ease-in-out opacity-0 group-hover:opacity-100`}>{item.verifyText}</span>
            </div>
        </div>
    </mdui-tooltip>
}

function GlobalLeaderBoard() {
    const [leaderBoard, setLeaderBoard] = useState([]);
    const {setIsAudioPlayerShowed} = useContext(AudioPlayerContext);
    useEffect(() => {
        fetchLeaderboard('global').then(result => {
            if (result.error) return snackbar({
                message: "获取排行榜数据失败，" + result.error,
                placement: 'top-end',
                closeable: true
            });
            setLeaderBoard(result);
        });
    }, []);
    return (
        <div className="mt-4 md:mt-0">
            <span
                className="text-2xl font-semibold my-4 bg-gradient-to-r from-orange-500 to-orange-300 text-transparent bg-clip-text">玩家 - 总排行榜</span><br/>
            <span className="text-sm text-gray-800">总排行榜为实时排行，仅显示全服务器Top20.</span>
            {
                leaderBoard.length > 0 ? <div className="mt-8 flex flex-col gap-y-4">
                        {
                            leaderBoard.map((item, index) => (
                                <div key={item.id} className="flex flex-row items-center pb-4 border-b">
                                    <span className="w-12 text-xl font-semibold">#{index + 1}</span>
                                    <img src={item.avatar} alt="avatar" className="border ml-2 w-10 h-10 rounded-full"/>
                                    {item.title &&
                                        <span
                                            className={`ml-2 text-lg ${item.isGuildTitle ? "text-purple-500" : "text-orange-500"}`}>{item.title}</span>
                                    }
                                    <div className="flex flex-row items-center justify-center">
                                    <span
                                        className={`${item.title ? 'ml-1' : 'ml-2'} text-lg max-w-[30vw] overflow-x-scroll`}
                                        style={{
                                            textWrap: 'nowrap',
                                            scrollbarWidth: 'none'
                                        }}>{item.name}</span>
                                        {
                                            (item.audio) &&
                                            <mdui-tooltip content={'MusicKit ' + item.audioName}><AudioPlayButton
                                                audio={{
                                                    id: item.id,
                                                    title: item.name + '\'s MVP MusicKit ' + item.audioName,
                                                    published: new Date(),
                                                    description: item.id,
                                                    content: item.id,
                                                    audio: {
                                                        src: "https://static.l0ve.lol/MusicKit/" + encodeURIComponent(item.audio.replace('vsnd', 'mp3').replace('#', '-')) + "?i=" + encodeURIComponent(item.id),
                                                        type: 'audio/mpeg'
                                                    }
                                                }}
                                                setIsAudioPlayerShowed={setIsAudioPlayerShowed}
                                                playing={<PauseIcon
                                                className="h-5 w-5 text-gray-500 hover:text-gray-900 cursor-pointer mx-1"></PauseIcon>}
                                            paused={<MusicalNoteIcon
                                                className="h-5 w-5 text-gray-500 hover:text-gray-900 cursor-pointer mx-1"></MusicalNoteIcon>}
                                        /></mdui-tooltip>
                                            }
                                    {
                                        item.nlusername ? <mdui-tooltip content={'Neverlose User ' + item.nlusername}><a
                                                target="_blank" href={
                                                item.nlShowType === 'profile' ? `https://neverlose.cc/profile?login=${item.nlusername}` : `https://en.neverlose.cc/market/?search=author%3A${item.nlusername}&type=0&sort=drec0`
                                            }><img alt="nlIcon" src="/img/nlIcon.jpeg"
                                                   className="h-5 w-5 rounded-[5px] mx-1"/></a></mdui-tooltip> : null
                                                }
                                    {
                                        item.verifyType && <VerifyTag item={item} />
                                    }
                                </div>
                                <div className="flex-grow"></div>
                                <span>总积分 {item.score}</span>
                            </div>
                        ))
                    }
                    </div> :
                    <div className="mt-4 flex flex-col items-center justify-center">
                        <span className="text-xl font-semibold my-4">暂无数据</span>
                    </div>
            }
        </div>
    );
}

function SeededShuffle(array, seed) {
    if (!seed) seed = 11451401337;

    function seededRandom() {
        let x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
    }

    let currentIndex = array.length;
    let temporaryValue, randomIndex;

    while (0 !== currentIndex) {
        randomIndex = Math.floor(seededRandom() * currentIndex);
        currentIndex--;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

function PerDayLeaderBoard({infoHook, originInfo}) {
    const [leaderBoard, setLeaderBoard] = useState([]);
    const {setIsAudioPlayerShowed} = useContext(AudioPlayerContext);
    const [leaderBoardInfo, setLeaderBoardInfo] = infoHook;

    const originalInfoRef = useRef();

    useEffect(() => {
        originalInfoRef.current = originInfo.perday;
        return () => {
            setLeaderBoardInfo(info => {
                info.perday = originalInfoRef.current;
                return { ...info};
            });
        }
    }, [originInfo]);

    const [date, setDate] = useState(new Date(new Date().getTime() + 8 * 60 * 60 * 1000).toISOString().split('T')[0]);
    function setPrevDate() {
        const d = new Date(date);
        d.setDate(d.getDate() - 1);
        setDate(d.toISOString().split('T')[0]);
    }
    function setNextDate() {
        const d = new Date(date);
        d.setDate(d.getDate() + 1);
        setDate(d.toISOString().split('T')[0]);
    }
    useEffect(() => {
        fetchLeaderboard(date).then(result => {
            if(result.error) return snackbar({
                message: "获取排行榜数据失败，" + result.error,
                placement: 'top-end',
                closeable: true
            });
            result = result.map((item, index) => {
                item.originIndex = index;
                return item;
            });
            if(date.includes('04-01')) result = result.reverse();
            if(date.includes('04-01')) result = SeededShuffle(result);
            setLeaderBoard(result);
            setLeaderBoardInfo(info => {
                info.perday = result.length;
                return { ...info};
            });
        });
    }, [date]);
    return (
        <div className="mt-4 md:mt-0">
            <span className="text-2xl font-semibold my-4 bg-gradient-to-r from-orange-500 to-orange-300 text-transparent bg-clip-text">玩家 - 每日排行榜</span><br/>
            <span className="text-sm text-gray-800">每日排行榜统计当天游玩的全部玩家的排行，最新一日的排行信息每五分钟刷新一次.</span><br />
            <div className="mt-2 flex flex-row items-center space-x-2">
                {
                    date === '2024-01-06' ? null : <ChevronLeftIcon onClick={setPrevDate} className="w-4 h-4 cursor-pointer"/>
                }
                <span className="text-sm">{date}</span>
                {
                    date === new Date(new Date().getTime() + 8 * 60 * 60 * 1000).toISOString().split('T')[0] ? null :
                    <ChevronRightIcon onClick={setNextDate} className="w-4 h-4 cursor-pointer" />
                }
            </div>
            {
                leaderBoard.length > 0 ? <div className="mt-8 flex flex-col gap-y-4">
                        {
                            leaderBoard.map((item, index) => (
                                <div key={item.userId} className="flex flex-row items-center pb-4 border-b">
                                    <span className="w-12 text-xl font-semibold">#{item.originIndex + 1}</span>
                                    <img src={item.user.avatar} alt="avatar" className="border ml-2 w-10 h-10 rounded-full" />
                                    { item.user.title &&
                                        <span className={`ml-2 text-lg ${item.user.isGuildTitle ? "text-purple-500" : "text-orange-500"}`}>{item.user.title}</span>
                                    }
                                    <div className="flex flex-row items-center justify-center">
                                        <span className={`${item.user.title ? 'ml-1' : 'ml-2'} text-lg`}>{item.user.name}</span>
                                        {
                                            (item.user.audio) && <mdui-tooltip content={'MusicKit ' + item.user.audioName}>
                                                <AudioPlayButton
                                                    audio={{
                                                        id: item.user.userId,
                                                        title: item.user.name + '\'s MVP MusicKit ' + item.user.audioName,
                                                        published: new Date(),
                                                        description: item.userId,
                                                        content: item.userId,
                                                        audio: {
                                                            src: "https://static.l0ve.lol/MusicKit/" + encodeURIComponent(item.user.audio.replace('vsnd', 'mp3').replace('#', '-')) + "?i=" + encodeURIComponent(item.userId),
                                                            type: 'audio/mpeg'
                                                        }
                                                    }}
                                                    setIsAudioPlayerShowed={setIsAudioPlayerShowed}
                                                    playing={<PauseIcon
                                                        className="h-5 w-5 text-gray-500 hover:text-gray-900 cursor-pointer mx-1"></PauseIcon>}
                                                    paused={<MusicalNoteIcon
                                                        className="h-5 w-5 text-gray-500 hover:text-gray-900 cursor-pointer mx-1"></MusicalNoteIcon>}
                                                /></mdui-tooltip>
                                                }
                                                {
                                            item.user.nlusername ? <mdui-tooltip content={'Neverlose User ' + item.user.nlusername}><a
                                                target="_blank" href={
                                                item.user.nlShowType === 'profile' ? `https://neverlose.cc/profile?login=${item.user.nlusername}` : `https://en.neverlose.cc/market/?search=author%3A${item.user.nlusername}&type=0&sort=drec0`
                                            }><img alt="nlIcon" src="/img/nlIcon.jpeg"
                                                   className="h-5 w-5 rounded-[5px] mx-1"/></a></mdui-tooltip> : null
                                        }
                                        {
                                            item.user.verifyType && <VerifyTag item={item.user} />
                                        }
                                    </div>
                                    <div className="flex-grow"></div>
                                    <span>{item.kill}K/{item.mvp}M/{item.score}T</span>
                                </div>
                            ))
                        }
                    </div> :
                    <div className="mt-4 flex flex-col items-center justify-center">
                        <span className="text-xl font-semibold my-4">暂无数据</span>
                    </div>
            }
        </div>
    );
}

function GlobalGuildLeaderBoard() {
    const [leaderBoard, setLeaderBoard] = useState([]);
    useEffect(() => {
        fetchGuildLeaderboard('global').then(result => {
            if(result.error) return snackbar({
                message: "获取排行榜数据失败，" + result.error,
                placement: 'top-end',
                closeable: true
            });
            setLeaderBoard(result);
        });
    }, []);
    return (
        <div className="mt-4 md:mt-0">
            <span className="text-2xl font-semibold my-4 bg-gradient-to-r from-orange-500 to-orange-300 text-transparent bg-clip-text">公会 - 总排行榜</span><br />
            <span className="text-sm text-gray-800">总排行榜为实时排行，仅显示全服务器Top20.</span>
            {
                leaderBoard.length > 0 ? <div className="mt-8 flex flex-col gap-y-4">
                        {
                            leaderBoard.map((item, index) => (
                                <div key={item.id} className="flex flex-row items-center pb-4 border-b">
                                    <span className="w-12 text-xl font-semibold">#{index + 1}</span>
                                    <img src={item.avatar} alt="avatar" className="border ml-2 w-10 h-10 rounded-full" />
                                    { item.title &&
                                        <span className={`ml-2 text-lg text-purple-500`}>{item.title}</span>
                                    }
                                    <span className={`${item.title ? 'ml-1' : 'ml-2'} text-lg`}>{item.name}</span>
                                    <div className="flex-grow"></div>
                                    <span>总积分 {item.score}</span>
                                </div>
                            ))
                        }
                    </div> :
                    <div className="mt-4 flex flex-col items-center justify-center">
                        <span className="text-xl font-semibold my-4">暂无数据</span>
                    </div>
            }
        </div>
    );
}

function PerDayGuildLeaderBoard({ infoHook, originInfo }) {
    const [leaderBoard, setLeaderBoard] = useState([]);
    const [date, setDate] = useState(new Date(new Date().getTime() + 8 * 60 * 60 * 1000).toISOString().split('T')[0]);

    const [leaderBoardInfo, setLeaderBoardInfo] = infoHook;

    const originalInfoRef = useRef();

    useEffect(() => {
        originalInfoRef.current = originInfo.guildPerday;
        return () => {
            setLeaderBoardInfo(info => {
                info.guildPerday = originalInfoRef.current;
                return { ...info};
            });
        }
    }, [originInfo]);

    function setPrevDate() {
        const d = new Date(date);
        d.setDate(d.getDate() - 1);
        setDate(d.toISOString().split('T')[0]);
    }
    function setNextDate() {
        const d = new Date(date);
        d.setDate(d.getDate() + 1);
        setDate(d.toISOString().split('T')[0]);
    }
    useEffect(() => {
        fetchGuildLeaderboard(date).then(result => {
            if(result.error) return snackbar({
                message: "获取排行榜数据失败，" + result.error,
                placement: 'top-end',
                closeable: true
            });
            setLeaderBoard(result);
            setLeaderBoardInfo(info => {
                info.guildPerday = result.length;
                return { ...info};
            });
        });
    }, [date]);
    return (
        <div className="mt-4 md:mt-0">
            <span className="text-2xl font-semibold my-4 bg-gradient-to-r from-orange-500 to-orange-300 text-transparent bg-clip-text">公会 - 每日排行榜</span><br/>
            <span className="text-sm text-gray-800">每日排行榜统计当天游玩的全部玩家的排行，最新一日的排行信息每五分钟刷新一次.</span><br />
            <div className="mt-2 flex flex-row items-center space-x-2">
                {
                    date === '2024-02-05' ? null : <ChevronLeftIcon onClick={setPrevDate} className="w-4 h-4 cursor-pointer"/>
                }
                <span className="text-sm">{date}</span>
                {
                    date === new Date(new Date().getTime() + 8 * 60 * 60 * 1000).toISOString().split('T')[0] ? null :
                        <ChevronRightIcon onClick={setNextDate} className="w-4 h-4 cursor-pointer" />
                }
            </div>
            {
                leaderBoard.length > 0 ? <div className="mt-8 flex flex-col gap-y-4">
                        {
                            leaderBoard.map((item, index) => (
                                <div key={item.guildId} className="flex flex-row items-center pb-4 border-b">
                                    <span className="w-12 text-xl font-semibold">#{index + 1}</span>
                                    <img src={item.guild.avatar} alt="avatar" className="border ml-2 w-10 h-10 rounded-full" />
                                    { item.guild.title &&
                                        <span className={`ml-2 text-lg text-purple-500`}>{item.guild.title}</span>
                                    }
                                    <span className={`${item.guild.title ? 'ml-1' : 'ml-2'} text-lg`}>{item.guild.name}</span>
                                    <div className="flex-grow"></div>
                                    <span>{item.kill}K/{item.mvp}M/{item.score}T</span>
                                </div>
                            ))
                        }
                    </div> :
                    <div className="mt-4 flex flex-col items-center justify-center">
                        <span className="text-xl font-semibold my-4">暂无数据</span>
                    </div>
            }
        </div>
    );
}

export default function LeaderBoard() {
    const [selected, setSelected] = useState('perday');
    const [leaderBoardInfo, setLeaderBoardInfo] = useState({
        perday: null,
        guildPerday: null,
    });
    const [leaderBoardInfoOriginal, setLeaderBoardInfoOriginal] = useState({
        perday: null,
        guildPerday: null,
    });

    useEffect(() => {
        fetchLeaderBoardInfo().then(result => {
            if(result.error) return snackbar({
                message: "获取排行榜数据失败，" + result.error,
                placement: 'top-end',
                closeable: true
            });
            setLeaderBoardInfo(result);
            setLeaderBoardInfoOriginal(result);
        });
    }, []);

    const tabs = [
        { name: '玩家 - 总排行榜', href: 'global', current: selected === 'global' },
        { name: '玩家 - 每日排行', href: 'perday', count: leaderBoardInfo.perday, current: selected === 'perday' },
        { name: '公会 - 总排行榜', href: 'guild-global', current: selected === 'guild-global' },
        { name: '公会 - 每日排行', href: 'guild-perday', count: leaderBoardInfo.guildPerday, current: selected === 'guild-perday' }
    ]

    return (
        <div className="mt-4 md:mt-0">
            {
                /*
                <div className="mb-4 md:mb-0 mt-2 flex flex-col p-3 rounded-2xl ring-1 ring-orange-500 bg-orange-50/20 space-y-2">
                <div className="flex flex-row text-gray-800 text-lg font-bold">周末排行榜活动进行中🎉</div>
                <p className="text-gray-800">
                    <span className="font-semibold">预热活动</span><br/>
                    - 本周五(4/19)排行榜前三名 38.8盲盒任抽一个 或 等价模型
                </p>
                <p className="text-gray-800">
                    <span className="font-semibold">赞助活动</span> - 感谢不知名大佬赞助<br/>
                    - 本周六(4/20)排行榜前三名 128盲盒任抽一个 或 等价模型<br/>
                    - 本周日(4/21)排行榜前三名 128盲盒任抽一个 或 等价模型<br/>
                </p>
                <span className="text-gray-800 text-xs">注: 赞助活动如获奖名额有重复则向后自动顺延，直至满六人</span>
            </div>
                */
            }
            <mdui-dropdown class="md:hidden">
                <mdui-button slot="trigger" class="bg-orange-500">{
                    selected === 'perday' ? '玩家 - 每日排行' :
                        selected === 'global' ? '玩家 - 总排行榜' :
                            selected === 'guild-global' ? '公会 - 总排行榜' : '公会 - 每日排行'
                }</mdui-button>
                <mdui-menu>
                    <div onClick={() => setSelected('global')}>
                        <mdui-menu-item>玩家 - 总排行榜</mdui-menu-item>
                    </div>
                    <div onClick={() => setSelected('perday')}>
                        <mdui-menu-item>玩家 - 每日排行</mdui-menu-item>
                    </div>
                    <div onClick={() => setSelected('guild-global')}>
                        <mdui-menu-item>公会 - 总排行榜</mdui-menu-item>
                    </div>
                    <div onClick={() => setSelected('guild-perday')}>
                        <mdui-menu-item>公会 - 每日排行</mdui-menu-item>
                    </div>
                </mdui-menu>
            </mdui-dropdown>
            <div className="hidden md:block">
                <div className="hidden md:block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {tabs.map((tab) => (
                                <a
                                    key={tab.name}
                                    href=""
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSelected(tab.href);
                                    }}
                                    className={classNames(
                                        tab.current
                                            ? 'border-orange-500 text-orange-600'
                                            : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                                        'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                                    )}
                                    aria-current={tab.current ? 'page' : undefined}
                                >
                                    {tab.name}
                                    {tab.count ? (
                                        <span
                                            className={classNames(
                                                tab.current ? 'bg-orange-100 text-orange-600' : 'bg-gray-100 text-gray-900',
                                                'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                                            )}
                                        >
                    {tab.count}
                  </span>
                                    ) : null}
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
            <br/>
            <div className="relative max-w-[100vw] flow-root overflow-x-scroll">
                {selected === 'perday' ? <PerDayLeaderBoard originInfo={leaderBoardInfoOriginal}
                                                            infoHook={[leaderBoardInfo, setLeaderBoardInfo]}/> :
                    selected === 'global' ? <GlobalLeaderBoard/> :
                        selected === 'guild-global' ? <GlobalGuildLeaderBoard/> :
                            <PerDayGuildLeaderBoard originInfo={leaderBoardInfoOriginal}
                                                    infoHook={[leaderBoardInfo, setLeaderBoardInfo]}/>}
            </div>
        </div>
    );
}
