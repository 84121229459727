import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'mdui';
import 'mdui/mdui.css';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { AudioProvider } from "./components/AudioProvider";
import { setColorScheme } from 'mdui/functions/setColorScheme.js';

setColorScheme('#FC9F4D');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AudioProvider>
        <Router>
            <App />
        </Router>
    </AudioProvider>
);
