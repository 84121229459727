export default function Home() {
    return (
        <div className="flex flex-col items-center justify-center" style={{
            minHeight: 'calc(100vh - 13.05rem)',
        }}>
            <img src="/img/mainPic.png" alt="mainPic" className="h-[20rem] w-[20rem] rounded-2xl" />
            <span className="mt-16 text-6xl text-orange-500">L0veL Server</span>
            <span className="mt-8 text-4xl text-gray-300"><span className="inline-block text-orange-300">L</span>0ve . <span className="inline-block text-orange-300">L</span>oL</span>
        </div>
    );
}
