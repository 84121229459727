import { useLocalStorage } from 'react-use';
import { useState, useEffect } from "react";
import { fetchUserInfo } from "../api/auth";
import { getToken } from "../api/constant";

const useAuth = () => {
    const [isAuthed, setIsAuthed] = useLocalStorage('isAuthed', false);
    const [Auth, setAuth] = useState({});

    function refreshAuth() {
        if(!getToken()) {
            setUnauthed();
            return;
        }
        fetchUserInfo().then(result => {
            if(!result.error) setAuth({...result});
            else {
                setUnauthed();
                localStorage.removeItem('sessionToken');
            }
        });
    }

    function setAuthed() {
        console.log('setAuthed')
        setIsAuthed(true);
        fetchUserInfo().then(result => {
            if(!result.error) setAuth({...result});
            else setUnauthed();
        });
    }

    function setUnauthed() {
        setIsAuthed(false);
        setAuth({});
    }

    useEffect(() => {
        if (isAuthed && !Auth.username) {
            if(!getToken()) {
                setUnauthed();
                return;
            }
            fetchUserInfo().then(result => {
                if(!result.error) setAuth({...result});
                else {
                    setUnauthed();
                    localStorage.removeItem('sessionToken');
                }
            });
        }
    }, [isAuthed]);

    return {
        isAuthed,
        Auth,
        setAuthed,
        setUnauthed,
        refreshAuth
    }
};

export default useAuth;
