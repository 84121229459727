import { CheckIcon } from '@heroicons/react/20/solid';
import { submitBuyRequest } from "../api/auth";
import { snackbar } from "mdui/functions/snackbar.js";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Shop() {

    function submitBuy(id) {
        submitBuyRequest(id).then(result => {
            if(result.error) {
                snackbar({
                    message: "购买失败，" + result.error,
                    placement: 'top-end',
                    closeable: true
                });
                return;
            }
            window.location.href = result;
        });
    }

    const packs = [
        {
            name: '1个月VIP',
            id: 'vip-1mo',
            main: 'HK$33',
            sub: '/ ~¥29.99',
            onClick: () => {
                submitBuy('vip-1mo');
            },
            buttonText: '购买',
            description: '抟扶摇之姿，阅万里坤灵',
            features: ['25个可用模型', '108个MVP音乐盒','1个自定义称号', '1个自定义公会', '更多抢先体验功能'],
            mostPopular: false
        },
        {
            name: '3个月VIP',
            id: 'vip-3mo',
            main: 'HK$96',
            sub: '/ ~¥86.88',
            onClick: () => {
                submitBuy('vip-3mo');
            },
            buttonText: '购买',
            description: '雕龙点睛，斩棘披靡',
            features: ['25个可用模型', '108个MVP音乐盒', '3个自定义称号', '1个自定义公会', '更多抢先体验功能'],
            mostPopular: false
        },
        {
            name: '6个月VIP',
            id: 'vip-6mo',
            main: 'HK$158',
            sub: '/ ~¥144',
            onClick: () => {
                submitBuy('vip-6mo');
            },
            buttonText: '购买',
            description: '凌云破日，振翅高飞',
            features: ['25个可用模型', '108个MVP音乐盒', '6个自定义称号', '1个自定义公会', '更多抢先体验功能'],
            mostPopular: true
        },
        {
            name: '12个月VIP',
            id: 'vip-12mo',
            main: 'HK$299',
            sub: '/ ~¥270',
            onClick: () => {
                submitBuy('vip-12mo');
            },
            buttonText: '购买',
            description: '星辰引归途，幽谷响箫声',
            features: ['25个可用模型', '108个MVP音乐盒', '12个自定义称号', '1个自定义公会', '更多抢先体验功能'],
            mostPopular: false
        },
    ]
    return (<>
        <h1 className="mt-4 text-2xl font-semibold leading-6 text-gray-900">VIP购买</h1>
        <div
            className="isolate mx-auto mt-8 mb-8 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3 2xl:grid-cols-4">
            {packs.map((pack) => (
                <div
                    key={pack.id}
                    className={classNames(
                        pack.mostPopular ? 'ring-2 ring-orange-600' : 'ring-1 ring-gray-200',
                        'rounded-3xl p-8'
                    )}
                >
                    <h3
                        id={pack.id}
                        className={classNames(
                            pack.mostPopular ? 'text-orange-600' : 'text-gray-900',
                            'text-lg font-semibold leading-8'
                        )}
                    >
                        {pack.name}
                    </h3>
                    <p className="mt-4 text-sm leading-6 text-gray-600">{pack.description}</p>
                    <p className="mt-6 flex items-baseline gap-x-1">
                        <span
                            className="text-4xl font-bold tracking-tight text-gray-900">{pack.discountMain || pack.main}</span>
                        <span
                            className="text-sm font-semibold leading-6 text-gray-600">{pack.discountSub || pack.sub}</span>
                    </p>
                    {
                        pack.discountMain ?
                            <p className="mt-1 text-sm leading-6 text-gray-600 line-through">打折前 {pack.main} {pack.sub}</p> : null
                    }
                    <div
                        onClick={pack.onClick}
                        aria-describedby={pack.id}
                        className={classNames(
                            pack.mostPopular
                                ? 'bg-orange-600 text-white shadow-sm hover:bg-orange-500'
                                : 'text-orange-600 ring-1 ring-inset ring-orange-200 hover:ring-orange-300',
                            'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 cursor-pointer'
                        )}
                    >
                        {pack.buttonText}
                    </div>
                    <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                        {pack.features.map((feature) => (
                            <li key={feature} className="flex gap-x-3">
                                <CheckIcon className="h-6 w-5 flex-none text-orange-600" aria-hidden="true"/>
                                {feature}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    </>)
}