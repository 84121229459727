import { useState, useContext, useEffect, useRef } from "react";
import { AuthContext } from "../App";
import { PlusIcon } from "@heroicons/react/24/solid";
import { KeyIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import issueCaptcha from "../tools/issueCaptcha";
import { submitProfile, fetchKeyCheck, submitNewKey, submitNewTitle, submitUsePrePaid, submitSetNLShowType } from "../api/auth";
import { snackbar } from "mdui/functions/snackbar.js";
import AudioPlayButton from "../components/AudioPlayButton";
import { apiUrl, getToken } from "../api/constant";
import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";
import { VerifyTag } from "./LeaderBoard";


export default function Links({ setIsAudioPlayerShowed }) {
    const { Auth, refreshAuth } = useContext(AuthContext);

    const [username, setUsername] = useState(null);

    const titles = Auth.titles ? [
        ...Auth.titles.length > 0 ? [{
            id: 'none',
            name: null
        }] : [], ...Auth.titles
    ] : [];

    const models = Auth.models ? [
        ...Auth.models.length > 0 ? [{
            id: 'none',
            name: null,
            modelIndex: null
        }] : [], ...Auth.models
    ] : [];

    const audios = Auth.audios ? [
        ...Auth.audios.length > 0 ? [{
            id: 'none',
            name: null,
            audio: null
        }] : [], ...Auth.audios
    ] : [];

    const [selectedTitle, setSelectedTitle] = useState({
        id: 'none',
        name: null
    });

    const [selectedModel, setSelectedModel] = useState({
        id: 'none',
        name: null,
        modelIndex: null
    });

    const [selectedAudio, setSelectedAudio] = useState({
        id: 'none',
        name: null,
        audio: null
    });

    useEffect(() => {
        setSelectedTitle((Auth.titles && Auth.titles.length > 0) ?
            (titles.find(title => {
                if(title.name === '跟随公会称号') return title.id === Auth.title;
                return title.name === Auth.title;
            }) || titles[0]) : {
                id: 'none',
                name: null
            });
        setSelectedModel((Auth.models && Auth.models.length > 0) ?
            (models.find(model => model.modelIndex === Auth.model) || models[0]) : {
                id: 'none',
                name: null,
                modelIndex: null
            });
        setSelectedAudio((Auth.audios && Auth.audios.length > 0) ?
            (audios.find(audio => audio.audio === Auth.audio) || audios[0]) : {
                id: 'none',
                name: null,
                audio: null
            });
    }, [Auth]);

    const [authKey, setAuthKey] = useState(null);
    const authKeyRef = useRef(null);
    const authCheckIntervalRef = useRef(null);

    useEffect(() => {
        submitNewKey().then(result => {
            if(result.error) {
                snackbar({
                    message: "获取密钥失败，" + result.error,
                    placement: 'top-end',
                    closeable: true
                });
                return;
            }
            setAuthKey(result);
            authKeyRef.current = result;
            authCheckIntervalRef.current = setInterval(() => {
                fetchKeyCheck(authKeyRef.current).then(result => {
                    if(result.error) {
                        submitNewKey().then(result => {
                            if(result.error) {
                                snackbar({
                                    message: "获取密钥失败，" + result.error,
                                    placement: 'top-end',
                                    closeable: true
                                });
                                return;
                            }
                            authKeyRef.current = result;
                            setAuthKey(result);
                        });
                    }
                });
            }, 1000 * 3);
        });
        return () => {
            if(authCheckIntervalRef.current) clearInterval(authCheckIntervalRef.current);
        };
    }, []);

    function setProfile() {
        const username = document.getElementById('username').value;
        const titleId = selectedTitle.id;
        const modelId = selectedModel.id;
        const musicBoxId = selectedAudio.id;
        if(!username) {
            snackbar({
                message: "用户名不能为空",
                placement: 'top-end',
                closeable: true
            });
            return;
        }
        submitProfile(username, titleId, modelId, musicBoxId).then(result => {
            if(result.error) {
                snackbar({
                    message: "设置失败，" + result.error,
                    placement: 'top-end',
                    closeable: true
                });
                return;
            }
            snackbar({
                message: "个人资料修改成功",
                placement: 'top-end',
                closeable: true
            });
            refreshAuth();
        });
    }

    const showMethods = [
        { id: 'none', title: '不展示' },
        { id: 'profile', title: '跳转到个人资料' },
        { id: 'market', title: '跳转到Market页面' },
    ]

    return (
        <div className="max-w-4xl mx-auto">
            <>
                <div className="space-y-12">
                    <div className="pb-12 border-b">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">身份令牌</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            在游戏聊天中发送下方显示的携带一次性鉴权令牌的指令以完成单次登录并在游戏中显示称号、用户名以及执行特权指令和通过在游戏中完成任务来积分，在完成一次认证后一次性令牌将失效并在此自动刷新。
                        </p>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label htmlFor="token" className="block text-sm font-medium leading-6 text-gray-900">
                                    鉴权指令
                                </label>
                                <div className="mt-2">
                                    <div
                                        className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange-600 sm:max-w-md">
                                        <input
                                            type="text"
                                            name="token"
                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-0 focus:ring-0 sm:text-sm sm:leading-6"
                                            value={'.login ' + authKey}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pb-12 border-b">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">会员连接</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            购买L0 VIP通行证以抢先体验各种新功能并获得之后到来的更多特权。
                        </p>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <div className="flex flex-row">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        VIP状态
                                    </label>
                                    <div className="flex-grow"></div>
                                    <mdui-dialog
                                        id="withKeyDialog"
                                        close-on-esc
                                        close-on-overlay-click
                                    >
                                        <h1 className="text-base font-semibold">兑换预付激活卡</h1>
                                        <p className="mt-1 text-xs leading-6 text-gray-600">
                                            在此兑换在第三方购买的L0权益激活卡<br/>兑换后将会自动向该账户添加对应的权益。
                                        </p>
                                        <mdui-text-field id="cardKey"
                                                         class="mt-4 p-0 focus:ring-0 outline-0 border-0"
                                                         label="卡密"></mdui-text-field>
                                        <div className="mt-4 flex items-center justify-end gap-x-6">
                                            <button
                                                onClick={() => {
                                                    const dialog = document.getElementById('withKeyDialog');
                                                    dialog.open = false;
                                                    issueCaptcha().then(result => {
                                                        submitUsePrePaid(document.getElementById('cardKey').value, result.token, result.type).then(R => {
                                                            if (R.error) return snackbar({
                                                                message: "兑换失败，" + R.error,
                                                                placement: 'top-end',
                                                                closeable: true
                                                            });
                                                            snackbar({
                                                                message: R.message,
                                                                placement: 'top-end',
                                                                closeable: true
                                                            });
                                                            refreshAuth();
                                                        });
                                                    });
                                                }}
                                                className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                            >
                                                兑换
                                            </button>
                                        </div>
                                    </mdui-dialog>
                                    <button onClick={() => {
                                        const dialog = document.getElementById('withKeyDialog');
                                        dialog.open = true;
                                    }}
                                            className="flex-none bg-orange-600 text-white px-3 py-1 rounded-lg text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2">
                                        <KeyIcon className="h-4 w-4"/>
                                    </button>
                                </div>
                                <div className="mt-2">
                                    <span>
                                        {Auth.vipExpireAt ? '于 ' +
                                            new Date(Auth.vipExpireAt).toLocaleDateString('zh-CN', {
                                                timeZone: 'Asia/Shanghai'
                                            }) + ' 到期' : '未开通'}
                                    </span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pb-12 border-b">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">账户绑定</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            绑定外围账户以在L0获得更好的体验。
                        </p>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <div className="flex flex-row">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Neverlose账户
                                    </label>
                                    <div className="flex-grow"></div>
                                    <mdui-dialog
                                        id="nlBindDialog"
                                        close-on-esc
                                        close-on-overlay-click
                                    >
                                        <h1 className="text-base font-semibold">关于Neverlose账户绑定</h1>
                                        <p className="mt-1 text-xs leading-6 text-gray-600">
                                            绑定Neverlose账户后，您可以选择在排行榜上展示NL图标，其他玩家在点击图标后可以被跳转至您的NL个人主页或市场列表（展示您的NL
                                            Market物品）。<br/>如果您已经绑定NL账户，在以后L0向用户发放与NL账户相关的礼品时将无需您做出操作。
                                        </p>
                                    </mdui-dialog>
                                    <button onClick={() => {
                                        const dialog = document.getElementById('nlBindDialog');
                                        dialog.open = true;
                                    }}
                                            className="flex-none bg-orange-600 text-white px-3 py-1 rounded-lg text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2">
                                        <QuestionMarkCircleIcon className="h-4 w-4"/>
                                    </button>
                                </div>
                                <div className="mt-2">
                                    <span>
                                        {
                                            Auth.nlusername ? <span className="inline-block">
                                                已绑定至 <a target="_blank"
                                                            href={"https://neverlose.cc/profile?login=" + Auth.nlusername}
                                                            className="underline text-orange-600">{Auth.nlusername}</a>
                                            </span> : ''
                                        }
                                        {
                                            !Auth.nlusername ? <div className="max-w-[24rem]">
                                                <a
                                                    href={"https://auth.l0ve.lol/api/account/oauth/neverlose/start?from=" + encodeURIComponent(window.location.origin) + "&bind=" + getToken()}
                                                    className="flex w-full items-center justify-center gap-1.5 rounded-md bg-black px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus-visible:ring-transparent"
                                                >
                                                    <img alt="nlIcon" src="/img/nlIcon.jpeg" className="h-5 w-5"
                                                         aria-hidden="true"/>
                                                    <span className="text-sm text-white font-semibold leading-6">绑定 Neverlose 账户</span>
                                                </a>
                                            </div> : null
                                        }
                                    </span>
                                </div>
                                {
                                    Auth.nlusername ? <div className="mt-2">
                                        <label
                                            className="block text-sm font-medium leading-6 text-gray-900">展示Neverlose资料</label>
                                        <p className="text-xs text-gray-500">希望如何在排行榜上展示绑定的NL账户</p>
                                        <fieldset className="mt-4">
                                            <div className="space-y-4">
                                                {showMethods.map((showMethod) => (
                                                    <div key={'nlshow' + showMethod.id} className="flex items-center">
                                                        <input
                                                            id={'nlshow' + showMethod.id}
                                                            name="nlShowMethod"
                                                            type="radio"
                                                            defaultChecked={showMethod.id === (Auth.nlShowType || 'none')}
                                                            onChange={() => {
                                                                submitSetNLShowType(showMethod.id).then(result => {
                                                                    if (result.error) {
                                                                        snackbar({
                                                                            message: "设置失败，" + result.error,
                                                                            placement: 'top-end',
                                                                            closeable: true
                                                                        });
                                                                        document.getElementById('nlshow' + (Auth.nlShowType || 'none')).checked = true;
                                                                        return;
                                                                    }
                                                                    snackbar({
                                                                        message: "设置成功",
                                                                        placement: 'top-end',
                                                                        closeable: true
                                                                    });
                                                                    refreshAuth();
                                                                });
                                                            }}
                                                            className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-600"
                                                        />
                                                        <label htmlFor={'nlshow' + showMethod.id}
                                                               className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                            {showMethod.title}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </fieldset>
                                    </div> : null
                                }
                            </div>
                        </div>
                        {
                            /*
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <div className="flex flex-row">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        贞德游戏论坛账户
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <span>
                                        {
                                            Auth.zhendeuid ? <span className="inline-block">
                                                已绑定至 UID:{Auth.zhendeuid}
                                            </span> : ''
                                        }
                                        {
                                            !Auth.zhendeuid ? <div className="max-w-[24rem]">
                                                <a
                                                    href={"https://auth.l0ve.lol/api/account/oauth/zhende/start?from=" + encodeURIComponent(window.location.origin) + "&bind=" + getToken()}
                                                    className="flex w-full items-center justify-center gap-1.5 rounded-md hover:brightness-105 bg-violet-600 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus-visible:ring-transparent"
                                                >
                                                    <img alt="zhendeIcon" src="/img/zhendeicon.ico" className="h-5 w-5"
                                                         aria-hidden="true"/>
                                                    <span className="text-sm text-white font-semibold leading-6">绑定 贞德游戏论坛 账户</span>
                                                </a>
                                            </div> : null
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                            */
                        }
                    </div>
                    <div className="pb-12">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">个人资料</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            在这里修改要在游戏中显示的个人资料。
                        </p>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                    用户名
                                </label>
                                <div className="mt-2">
                                    <div
                                        className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange-600 sm:max-w-md">
                                        <input
                                            type="text"
                                            name="username"
                                            id="username"
                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 focus:outline-0 sm:text-sm sm:leading-6"
                                            value={(!username && username !== '') ? Auth.name : username}
                                            onChange={e => setUsername(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-4">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                    L0veL 认证
                                </label>
                                <div className="flex mt-2">
                                    {
                                        Auth.verifyType ? <VerifyTag item={Auth}/> : <span>暂无认证</span>
                                    }
                                </div>
                            </div>
                            <div className="sm:col-span-4">
                                <mdui-dialog
                                    id="addTitleDialog"
                                    close-on-esc
                                    close-on-overlay-click
                                >
                                    <h1 className="text-base font-semibold">添加一个称号</h1>
                                    <p className="mt-1 text-xs leading-6 text-gray-600">
                                        每续费一个月VIP均可获得一次创建称号的机会，称号永久有效。<br/>剩余次数: {Auth.vipPaidCount}
                                    </p>
                                    <mdui-text-field id="newTitle" class="mt-4 p-0 focus:ring-0 outline-0 border-0"
                                                     label="称号"></mdui-text-field>
                                    <div className="mt-4 flex items-center justify-end gap-x-6">
                                        <button
                                            onClick={() => {
                                                const dialog = document.getElementById('addTitleDialog');
                                                dialog.open = false;
                                                submitNewTitle(document.getElementById('newTitle').value).then(result => {
                                                    if (result.error) {
                                                        snackbar({
                                                            message: "创建失败，" + result.error,
                                                            placement: 'top-end',
                                                            closeable: true
                                                        });
                                                        return;
                                                    }
                                                    snackbar({
                                                        message: "创建成功",
                                                        placement: 'top-end',
                                                        closeable: true
                                                    });
                                                    refreshAuth();
                                                });
                                            }}
                                            className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                        >
                                            创建
                                        </button>
                                    </div>
                                </mdui-dialog>
                                <div className="flex flex-row">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        称号列表
                                    </label>
                                    <div className="flex-grow"></div>
                                    <button onClick={() => {
                                        const dialog = document.getElementById('addTitleDialog');
                                        dialog.open = true;
                                    }}
                                            className="flex-none bg-orange-600 text-white px-3 py-1 rounded-lg text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2">
                                        <PlusIcon className="h-4 w-4"/>
                                    </button>
                                </div>

                                <div className="mt-2">
                                    <div
                                        className="space-y-4 sm:flex flex-wrap sm:items-center sm:space-x-10 sm:space-y-0">
                                        {titles.length > 0 ? titles.map((title) => (
                                            <div key={title.id} className="flex items-center">
                                                <input
                                                    id={title.id}
                                                    name="notification-method"
                                                    type="radio"
                                                    checked={selectedTitle.id === title.id}
                                                    onChange={() => setSelectedTitle(title)}
                                                    className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-600"
                                                />
                                                <label htmlFor={title.id}
                                                       className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                    {title.name || '无称号'}
                                                </label>
                                            </div>
                                        )) : (
                                            <div className="flex w-full items-center justify-center">
                                                <span
                                                    className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                    暂无称号
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-4">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                    模型列表
                                </label>
                                <div className="mt-2">
                                    <div
                                        className="space-y-4 sm:flex flex-wrap sm:items-center sm:space-x-10 sm:space-y-0">
                                        {models.length > 0 ? models.map((model) => (
                                            <div key={model.id} className="flex items-center">
                                                <input
                                                    id={'model' + model.id}
                                                    name="models"
                                                    type="radio"
                                                    checked={selectedModel.id === model.id}
                                                    onChange={() => setSelectedModel(model)}
                                                    className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-600"
                                                />
                                                <label htmlFor={'model' + model.id}
                                                       className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                    {model.name || '无模型'}
                                                </label>
                                            </div>
                                        )) : (
                                            <div className="flex w-full items-center justify-center">
                                                <span
                                                    className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                    暂无模型
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-4">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                    音乐盒列表
                                </label>
                                <div className="mt-2">
                                    <div
                                        className="space-y-4 sm:flex flex-wrap sm:items-center sm:space-x-10 sm:space-y-0">
                                        {audios.length > 0 ? audios.map((audio) => (
                                            <div key={audio.id} className="flex items-center">
                                                <input
                                                    id={'audio' + audio.id}
                                                    name="audios"
                                                    type="radio"
                                                    checked={selectedAudio.id === audio.id}
                                                    onChange={() => setSelectedAudio(audio)}
                                                    className="h-4 w-4 border-gray-300 text-orange-600 focus:ring-orange-600"
                                                />
                                                <label htmlFor={'audio' + audio.id}
                                                       className="ml-3 flex items-center justify-center text-sm font-medium leading-6 text-gray-900">
                                                    {audio.name || '无音乐盒'}
                                                    {
                                                        (audio.name !== '无音乐盒' && audio.audio) && <AudioPlayButton
                                                            audio={{
                                                                id: audio.id,
                                                                title: 'MusicKit Preview ' + audio.name,
                                                                published: new Date(),
                                                                description: audio.id,
                                                                content: audio.id,
                                                                audio: {
                                                                    src: "https://static.l0ve.lol/MusicKit/" + encodeURIComponent(audio.audio.split('|')[0].split('/').slice(-1).pop().replace('vsnd', 'mp3').replace('#', '-')),
                                                                    type: 'audio/mpeg'
                                                                }
                                                            }}
                                                            setIsAudioPlayerShowed={setIsAudioPlayerShowed}
                                                            playing={<PauseIcon
                                                                className="h-4 w-4 text-gray-500 hover:text-gray-900 cursor-pointer"></PauseIcon>}
                                                            paused={<PlayIcon
                                                                className="h-4 w-4 text-gray-500 hover:text-gray-900 cursor-pointer"></PlayIcon>}
                                                        />
                                                    }
                                                </label>
                                            </div>
                                        )) : (
                                            <div className="flex w-full items-center justify-center">
                                                <span
                                                    className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                    暂无音乐盒
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 flex items-center justify-end gap-x-6">
                            <button
                                onClick={setProfile}
                                className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                            >
                                保存
                            </button>
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}
