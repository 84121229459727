import { apiUrl, isDev, isCORS, getToken } from "./constant";

export async function fetchUserInfo() {
    try {
        const response = await fetch(`${apiUrl}/auth/info`, isDev ? {
            credentials: 'include'
        } : {
            ...isCORS ? {
                headers: {
                    'Authorization': 'Bearer ' + getToken()
                }
            } : {}
        });
        const result = await response.json();
        if(result.status === 200) {
            return result.data;
        }
        else return { error: result.message };
    }
    catch (e) {
        return { error: 'Failed to Fetch.' };
    }
}

export async function submitRegister(email, password, token, type) {
    try {
        const response = await fetch(`${apiUrl}/account/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                password,
                token,
                type
            })
        });
        const result = await response.json();
        if(result.status === 200) {
            return result.token;
        }
        else return { error: result.message };
    }
    catch (e) {
        return { error: 'Failed to Fetch.' };
    }
}

export async function submitRequestPasswordReset(email, token, type) {
    try {
        const response = await fetch(`${apiUrl}/account/requestPasswordReset`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                token,
                type
            })
        });
        const result = await response.json();
        if(result.status === 200) {
            return result;
        }
        else return { error: result.message };
    }
    catch (e) {
        return { error: 'Failed to Fetch.' };
    }
}

export async function submitResetPassword(email, password, uuid) {
    try {
        const response = await fetch(`${apiUrl}/account/resetPassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                password,
                uuid
            })
        });
        const result = await response.json();
        if(result.status === 200) {
            return result;
        }
        else return { error: result.message };
    }
    catch (e) {
        return { error: 'Failed to Fetch.' };
    }
}

export async function submitLogin(email, password) {
    try {
        const response = await fetch(`${apiUrl}/account/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                password
            })
        });
        const result = await response.json();
        if(result.status === 200) {
            return result.token;
        }
        else return { error: result.message };
    }
    catch (e) {
        return { error: 'Failed to Fetch.' };
    }
}

export async function submitProfile(name, titleId, modelId, musicBoxId) {
    try {
        const response = await fetch(`${apiUrl}/profile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            },
            body: JSON.stringify({
                name,
                titleId,
                modelId,
                musicBoxId
            })
        });
        const result = await response.json();
        if(result.status === 200) {
            return result;
        }
        else return { error: result.message };
    }
    catch (e) {
        return { error: 'Failed to Fetch.' };
    }
}

export async function submitNewKey(){
    try {
        const response = await fetch(`${apiUrl}/auth/newKey`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getToken()
            }
        });
        const result = await response.json();
        if(result.status === 200) {
            return result.data;
        }
        else return { error: result.message };
    }
    catch (e) {
        return { error: 'Failed to Fetch.' };
    }
}

export async function fetchKeyCheck(key) {
    try {
        const response = await fetch(`${apiUrl}/auth/checkKey/` + key, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + getToken()
            }
        });
        const result = await response.json();
        if(result.status === 200) {
            return result;
        }
        else return { error: result.message };
    }
    catch (e) {
        return { error: 'Failed to Fetch.' };
    }
}

export async function fetchLeaderboard(key) {
    try {
        const response = await fetch(`${apiUrl}/score/${key}`);
        const result = await response.json();
        if(result.status === 200) {
            return result.data;
        }
        if(result.status === 404) {
            return [];
        }
        return { error: result.message };
    }
    catch (e) {
        return { error: 'Failed to Fetch.' };
    }
}

export async function fetchGuildLeaderboard(key) {
    try {
        const response = await fetch(`${apiUrl}/guildScore/${key}`);
        const result = await response.json();
        if(result.status === 200) {
            return result.data;
        }
        if(result.status === 404) {
            return [];
        }
        return { error: result.message };
    }
    catch (e) {
        return { error: 'Failed to Fetch.' };
    }
}

export async function submitBuyRequest(id) {
    try {
        const response = await fetch(`${apiUrl}/payment/newOrder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            },
            body: JSON.stringify({
                productId: id
            })
        });
        const result = await response.json();
        if(result.status === 200) {
            return result.data;
        }
        else return { error: result.message };
    }
    catch (e) {
        return { error: 'Failed to Fetch.' };
    }
}

export async function submitNewTitle(title) {
    try {
        const response = await fetch(`${apiUrl}/title`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            },
            body: JSON.stringify({
                name: title
            })
        });
        const result = await response.json();
        if(result.status === 200) {
            return result;
        }
        else return { error: result.message };
    }
    catch (e) {
        return { error: 'Failed to Fetch.' };
    }
}

export async function submitUsePrePaid(card, token, type) {
    try {
        const response = await fetch(`${apiUrl}/payment/withPrePaid`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            },
            body: JSON.stringify({
                card,
                token,
                type
            })
        });
        const result = await response.json();
        if(result.status === 200) {
            return result;
        }
        else return { error: result.message };
    }
    catch (e) {
        return { error: 'Failed to Fetch.' };
    }
}

export async function submitNewInviteCodeRequest() {
    try {
        const response = await fetch(`${apiUrl}/guild/setInviteCode`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getToken()
            }
        });
        const result = await response.json();
        if(result.status === 200) {
            return result;
        }
        else return { error: result.message };
    }
    catch (e) {
        return { error: 'Failed to Fetch.' };
    }
}

export async function submitNewGuild(name, title) {
    try {
        const response = await fetch(`${apiUrl}/guild`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            },
            body: JSON.stringify({
                name,
                title
            })
        });
        const result = await response.json();
        if (result.status === 200) {
            return result.data;
        } else return {error: result.message};
    }
    catch (e) {
        return {error: 'Failed to Fetch.'};
    }
}

export async function submitEditGuild(name, title) {
    try {
        const response = await fetch(`${apiUrl}/guild`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            },
            body: JSON.stringify({
                name,
                title
            })
        });
        const result = await response.json();
        if (result.status === 200) {
            return result;
        } else return {error: result.message};
    }
    catch (e) {
        return {error: 'Failed to Fetch.'};
    }
}

export async function fetchGuildInfo() {
    try {
        const response = await fetch(`${apiUrl}/guild`, {
            headers: {
                'Authorization': 'Bearer ' + getToken()
            }
        });
        const result = await response.json();
        if (result.status === 200) {
            return result.data;
        } else return {error: result.message};
    }
    catch (e) {
        return {error: 'Failed to Fetch.'};
    }
}

export async function kickGuildMember(id) {
    try {
        const response = await fetch(`${apiUrl}/guild/kick`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            },
            body: JSON.stringify({
                targetId: id
            })
        });
        const result = await response.json();
        if (result.status === 200) {
            return result;
        } else return {error: result.message};
    }
    catch (e) {
        return {error: 'Failed to Fetch.'};
    }
}

export async function setGuildMemberAdmin(id) {
    try {
        const response = await fetch(`${apiUrl}/guild/setAdmin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            },
            body: JSON.stringify({
                targetId: id
            })
        });
        const result = await response.json();
        if (result.status === 200) {
            return result;
        } else return {error: result.message};
    }
    catch (e) {
        return {error: 'Failed to Fetch.'};
    }
}

export async function removeGuildMemberAdmin(id) {
    try {
        const response = await fetch(`${apiUrl}/guild/removeAdmin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            },
            body: JSON.stringify({
                targetId: id
            })
        });
        const result = await response.json();
        if (result.status === 200) {
            return result;
        } else return {error: result.message};
    }
    catch (e) {
        return {error: 'Failed to Fetch.'};
    }
}

export async function submitInviteMember(code) {
    try {
        const response = await fetch(`${apiUrl}/guild/invite`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            },
            body: JSON.stringify({
                guildInviteCode: code
            })
        });
        const result = await response.json();
        if (result.status === 200) {
            return result;
        } else return {error: result.message};
    }
    catch (e) {
        return {error: 'Failed to Fetch.'};
    }
}

export async function submitLeaveGuild() {
    try {
        const response = await fetch(`${apiUrl}/guild/leave`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getToken()
            }
        });
        const result = await response.json();
        if (result.status === 200) {
            return result;
        } else return {error: result.message};
    } catch (e) {
        return {error: 'Failed to Fetch.'};
    }
}

export async function submitDisbandGuild() {
    try {
        const response = await fetch(`${apiUrl}/guild/disband`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getToken()
            }
        });
        const result = await response.json();
        if (result.status === 200) {
            return result;
        } else return {error: result.message};
    } catch (e) {
        return {error: 'Failed to Fetch.'};
    }
}

export async function fetchLeaderBoardInfo() {
    try {
        const response = await fetch(`${apiUrl}/score`);
        const result = await response.json();
        if(result.status === 200) {
            return result.data;
        }
        return { error: result.message };
    }
    catch (e) {
        return { error: 'Failed to Fetch.' };
    }
}

export async function submitSetNLShowType(method) {
    try {
        const response = await fetch(`${apiUrl}/nlShowType`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            },
            body: JSON.stringify({
                nlShowType: method
            })
        });
        const result = await response.json();
        if (result.status === 200) {
            return result;
        }
        return {error: result.message};
    }
    catch (e) {
        return {error: 'Failed to Fetch.'};
    }
}

export async function fetchServerList() {
    try {
        const response = await fetch(`${apiUrl}/server`);
        const result = await response.json();
        if(result.status === 200) {
            return result.data;
        }
        return { error: result.message };
    }
    catch (e) {
        return { error: 'Failed to Fetch.' };
    }
}
