export default function Verify() {
    return (
        <div>
            <div className="mdui-prose max-w-2xl">
                <h2>L0veL Verify</h2>
                <h4>个人认证</h4>
                <p>
                    L0veL个人认证旨在帮助玩家识别在L0veL服务器/平台中或HvH社区知名玩家的身份，避免冒充和欺诈行为。认证通过后便可在排行榜中通过黄色闪电认证标识来展示。
                    L0veL授予玩家个人认证不代表对该玩家的行为、言论、观点等的认可，也不代表L0veL对该玩家的行为提供任何担保，L0veL仅保证认证玩家的身份准确性，但不对其他任何方面负责。
                </p>
                <h4>组织认证</h4>
                <p>
                    L0veL组织认证旨在帮助玩家标识在L0veL服务器/平台中或HvH社区中的知名组织创始人/管理者/核心成员的身份，避免冒充和欺诈行为。认证通过后便可在排行榜中通过蓝色印章认证标识来展示。
                    L0veL授予组织认证不代表对该组织的行为、言论、观点等的认可，也不代表L0veL对该组织的行为提供任何担保，L0veL仅保证认证组织创始人/管理者/核心成员的身份准确性和组织的真实性，但不对其他任何方面负责。
                </p>
            </div>
        </div>
    );
}
