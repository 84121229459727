import { useState, useContext, useEffect, useRef } from "react";
import { AuthContext } from "../App";
import {PlusIcon, XMarkIcon, SparklesIcon, UserMinusIcon} from "@heroicons/react/24/outline";
import {
    submitNewInviteCodeRequest,
    submitNewGuild,
    fetchGuildInfo,
    submitEditGuild,
    kickGuildMember,
    submitInviteMember,
    submitLeaveGuild,
    setGuildMemberAdmin,
    removeGuildMemberAdmin,
    submitDisbandGuild
} from "../api/auth";
import { snackbar } from "mdui/functions/snackbar.js";
import issueCaptcha from "../tools/issueCaptcha";

function GuildManage() {
    const [guild, setGuild] = useState(null);
    const { Auth, refreshAuth } = useContext(AuthContext);

    function initGuild() {
        fetchGuildInfo().then((res) => {
            if(res.error) return snackbar({
                message: "获取公会信息失败，" + res.error,
                placement: 'top-end',
                closeable: true
            });
            setGuild(res);
            setName(res.name);
            setTitle(res.title);
            setIsMaster(Auth.id === res.userId);
            setIsAdmin(Auth.id === res.userId || res.admins.includes(Auth.id));
        });
    }

    useEffect(() => {
        initGuild();
    }, [Auth]);

    const [isMaster, setIsMaster] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const [name, setName] = useState('');
    const [title, setTitle] = useState('');

    function saveProfile() {
        const name = document.getElementById('name').value;
        const title = document.getElementById('title').value;
        if(!name || !title) return snackbar({
            message: "公会名称和称号不能为空",
            placement: 'top-end',
            closeable: true
        });
        submitEditGuild(name, title).then((res) => {
            if(res.error) return snackbar({
                message: "保存公会信息失败，" + res.error,
                placement: 'top-end',
                closeable: true
            });
            refreshAuth();
            snackbar({
                message: "保存公会信息成功",
                placement: 'top-end',
                closeable: true
            });
        });
    }

    return <div>
        <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">公会资料</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
                在这里查看{isMaster ? "/修改" : null}公会的基本资料
            </p>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                        公会名称
                    </label>
                    <div className="mt-2">
                        {
                            isMaster ? <div
                                className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange-600 sm:max-w-md">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 focus:outline-0 sm:text-sm sm:leading-6"
                                    value={(!name && name !== '') ? guild?.name : name}
                                    onChange={e => setName(e.target.value)}
                                /></div> : <span className="text-base text-gray-600">{guild?.name}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                        公会称号
                    </label>
                    <div className="mt-2">
                        {
                            isMaster ? <div
                                className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange-600 sm:max-w-md">
                                <input
                                    type="text"
                                    name="title"
                                    id="title"
                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 focus:outline-0 sm:text-sm sm:leading-6"
                                    value={(!title && title !== '') ? guild?.title : title}
                                    onChange={e => setTitle(e.target.value)}
                                /></div> : <span className="text-base text-gray-600">{guild?.title}</span>
                        }
                    </div>
                </div>
            </div>
            {
                isMaster ? <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        onClick={saveProfile}
                        className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                    >
                        保存
                    </button>
                </div> : null
            }
        </div>
        <div className="my-8 w-full border-b"></div>
        <div>
            <div className="flex flex-row">
                <h2 className="text-base font-semibold leading-7 text-gray-900">公会成员</h2>
                <div className="flex-grow"/>
                {
                    (isMaster || isAdmin) && <>
                        <mdui-dialog
                            id="inviteMember"
                            close-on-esc
                            close-on-overlay-click
                        >
                            <h1 className="text-base font-semibold">邀请成员</h1>
                            <p className="mt-1 text-xs leading-6 text-gray-600">
                                输入 L0-User-Inv- 起始的邀请码以邀请新成员加入公会
                            </p>
                            <mdui-text-field id="inviteCode" class="mt-4 p-0 focus:ring-0 outline-0 border-0" label="邀请码"></mdui-text-field>
                            <div className="mt-4 flex items-center justify-end gap-x-6">
                                <button
                                    onClick={() => {
                                        const dialog = document.getElementById('inviteMember');
                                        dialog.open = false;
                                        const code = document.getElementById('inviteCode').value;
                                        if (!code) return snackbar({
                                            message: "邀请码不能为空",
                                            placement: 'top-end',
                                            closeable: true
                                        });
                                        submitInviteMember(code).then((res) => {
                                            if (res.error) return snackbar({
                                                message: "邀请玩家加入失败，" + res.error,
                                                placement: 'top-end',
                                                closeable: true
                                            });
                                            initGuild();
                                            snackbar({
                                                message: "邀请玩家加入成功",
                                                placement: 'top-end',
                                                closeable: true
                                            });
                                        });
                                    }}
                                    className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                >
                                    邀请
                                </button>
                            </div>
                        </mdui-dialog>
                        <button onClick={() => {
                            const dialog = document.getElementById('inviteMember');
                            dialog.open = true;
                        }}
                                className="flex-none bg-orange-600 text-white px-3 py-1 rounded-lg text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2">
                            <PlusIcon className="h-4 w-4"/>
                        </button>
                    </>
                }
            </div>

            <p className="mt-1 text-sm leading-6 text-gray-600">
                在这里查看{(isMaster || isAdmin) ? "/管理" : null}公会的所有成员
            </p>
            <div className="mt-8 flex flex-col gap-y-4">
                {
                    guild?.members && guild.members.map(item => (
                        <div key={item.id} className="flex flex-row items-center pb-4 border-b">
                            <img src={item.avatar} alt="avatar" className="border w-10 h-10 rounded-full"/>
                            {item.title &&
                                <span
                                    className={`ml-2 text-lg ${item.isGuildTitle ? "text-purple-500" : "text-orange-500"}`}>{item.title}</span>
                            }
                            <span className={`${item.title ? 'ml-1' : 'ml-2'} text-lg`}>{item.name}</span>
                            <div className="flex-grow"></div>
                            <mdui-dialog
                                id={"kickPlayerDialog-" + item.id}
                                close-on-esc
                                close-on-overlay-click
                            >
                                <h1 className="text-base font-semibold">踢出成员</h1>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                    确定要踢出 {item.name} 吗？
                                </p>
                                <div className="mt-4 flex items-center justify-end gap-x-6">
                                    <button
                                        onClick={() => {
                                            const dialog = document.getElementById('kickPlayerDialog-' + item.id);
                                            dialog.open = false;
                                            kickGuildMember(item.id).then((res) => {
                                                if (res.error) return snackbar({
                                                    message: "踢出玩家失败，" + res.error,
                                                    placement: 'top-end',
                                                    closeable: true
                                                });
                                                initGuild();
                                                snackbar({
                                                    message: "踢出玩家成功",
                                                    placement: 'top-end',
                                                    closeable: true
                                                });
                                            });
                                        }}
                                        className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                    >
                                        踢出
                                    </button>
                                </div>
                            </mdui-dialog>
                            <mdui-dialog
                                id={"setPlayerAdminDialog-" + item.id}
                                close-on-esc
                                close-on-overlay-click
                            >
                                <h1 className="text-base font-semibold">提升成员权限</h1>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                    确定要将 {item.name} 设为公会管理员吗？
                                </p>
                                <div className="mt-4 flex items-center justify-end gap-x-6">
                                    <button
                                        onClick={() => {
                                            const dialog = document.getElementById('setPlayerAdminDialog-' + item.id);
                                            dialog.open = false;
                                            setGuildMemberAdmin(item.id).then((res) => {
                                                if (res.error) return snackbar({
                                                    message: "设置失败，" + res.error,
                                                    placement: 'top-end',
                                                    closeable: true
                                                });
                                                initGuild();
                                                snackbar({
                                                    message: "设置管理员成功",
                                                    placement: 'top-end',
                                                    closeable: true
                                                });
                                            });
                                        }}
                                        className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                    >
                                        确认
                                    </button>
                                </div>
                            </mdui-dialog>
                            <mdui-dialog
                                id={"removePlayerAdminDialog-" + item.id}
                                close-on-esc
                                close-on-overlay-click
                            >
                                <h1 className="text-base font-semibold">取消成员权限</h1>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                    确定要将 {item.name} 公会管理员的身份取消吗吗？
                                </p>
                                <div className="mt-4 flex items-center justify-end gap-x-6">
                                    <button
                                        onClick={() => {
                                            const dialog = document.getElementById('removePlayerAdminDialog-' + item.id);
                                            dialog.open = false;
                                            removeGuildMemberAdmin(item.id).then((res) => {
                                                if (res.error) return snackbar({
                                                    message: "删除失败，" + res.error,
                                                    placement: 'top-end',
                                                    closeable: true
                                                });
                                                initGuild();
                                                snackbar({
                                                    message: "删除管理员成功",
                                                    placement: 'top-end',
                                                    closeable: true
                                                });
                                            });
                                        }}
                                        className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                    >
                                        确认
                                    </button>
                                </div>
                            </mdui-dialog>
                            {
                                (isMaster && (item.id !== Auth.id && !guild.admins.includes(item.id))) &&
                                <SparklesIcon onClick={() => {
                                    const dialog = document.getElementById('setPlayerAdminDialog-' + item.id);
                                    dialog.open = true;
                                }} className="text-gray-600 h-6 w-6 cursor-pointer"/>
                            }
                            {
                                (isMaster && (item.id !== Auth.id && guild.admins.includes(item.id))) &&
                                <UserMinusIcon onClick={() => {
                                    const dialog = document.getElementById('removePlayerAdminDialog-' + item.id);
                                    dialog.open = true;
                                }} className="text-gray-600 h-6 w-6 cursor-pointer"/>
                            }
                            {
                                ((isMaster || isAdmin) && (item.id !== Auth.id && item.id !== guild.userId)) &&
                                <XMarkIcon onClick={() => {
                                    const dialog = document.getElementById('kickPlayerDialog-' + item.id);
                                    dialog.open = true;
                                }} className="text-gray-600 h-6 w-6 cursor-pointer"/>
                            }
                        </div>
                    ))
                }
            </div>
            {
                !isMaster && <>
                    <mdui-dialog
                        id="leaveGuildDialog"
                        close-on-esc
                        close-on-overlay-click
                    >
                        <h1 className="text-base font-semibold">退出公会</h1>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            确定要退出公会吗？
                        </p>
                        <div className="mt-4 flex items-center justify-end gap-x-6">
                            <button
                                onClick={() => {
                                    const dialog = document.getElementById('leaveGuildDialog');
                                    dialog.open = false;
                                    submitLeaveGuild().then((res) => {
                                        if (res.error) return snackbar({
                                            message: "退出公会失败，" + res.error,
                                            placement: 'top-end',
                                            closeable: true
                                        });
                                        refreshAuth();
                                        snackbar({
                                            message: "退出公会成功",
                                            placement: 'top-end',
                                            closeable: true
                                        });
                                    });
                                }}
                                className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                            >
                                退出
                            </button>
                        </div>
                    </mdui-dialog>
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                            onClick={() => {
                                const dialog = document.getElementById('leaveGuildDialog');
                                dialog.open = true;
                            }}
                            className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                        >
                            退出公会
                        </button>
                    </div>
                </>
            }
            {
                isMaster && <>
                    <mdui-dialog
                        id="disbandGuildDialog"
                        close-on-esc
                        close-on-overlay-click
                    >
                        <h1 className="text-base font-semibold">解散公会</h1>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            确定要解散公会吗？公会一经解散将立即失去所有成员并无法找回，并且将在总排行榜上被移除，但在每日排行榜上产生的历史分数会得到保留。公会名称和称号将被立即释放，请谨防抢注，管理组不对解散公会后名称被抢注负责。
                        </p>
                        <div className="mt-4 flex items-center justify-end gap-x-6">
                            <button
                                onClick={() => {
                                    const dialog = document.getElementById('disbandGuildDialog');
                                    dialog.open = false;
                                    submitDisbandGuild().then((res) => {
                                        if (res.error) return snackbar({
                                            message: "解散公会失败，" + res.error,
                                            placement: 'top-end',
                                            closeable: true
                                        });
                                        refreshAuth();
                                        snackbar({
                                            message: "解散公会成功",
                                            placement: 'top-end',
                                            closeable: true
                                        });
                                    });
                                }}
                                className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                            >
                                解散
                            </button>
                        </div>
                    </mdui-dialog>
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                            onClick={() => {
                                const dialog = document.getElementById('disbandGuildDialog');
                                dialog.open = true;
                            }}
                            className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                        >
                            解散公会
                        </button>
                    </div>
                </>
            }
        </div>
    </div>

}

export default function Guild() {
    const {Auth, refreshAuth} = useContext(AuthContext);

    let InterValRef = useRef(null);

    useEffect(() => {
        if (Auth.id && !Auth.guildId && Auth.guildInviteCode && !InterValRef.current) {
            InterValRef.current = setInterval(() => {
                refreshAuth();
            }, 5000);
        }
        if (Auth.guildId && InterValRef.current) {
            clearInterval(InterValRef.current);
            InterValRef.current = null;
        }
        return () => {
            if (InterValRef.current) {
                clearInterval(InterValRef.current);
                InterValRef.current = null;
            }
        };
    }, [Auth]);

    return (
        <div>
            <div className="mdui-prose">
                <h2>公会</h2>
                <p>通过L0提供的公会系统，您可以与熟悉的玩家共同挑战全新的排行榜系统，结识伙伴、赢取奖励并为名誉而战。</p>
            </div>
            <div className="my-8 w-full border-b"></div>
            {
                !Auth.guildId ? <div className="flex flex-col space-y-4 w-full mt-4 justify-center items-center">
                    <span>您尚未加入公会</span>
                    <div className="flex flex-col space-y-1 justify-center items-center">
                        {
                            !Auth.guildInviteCode ? <button
                                    onClick={() => {
                                        submitNewInviteCodeRequest().then((res) => {
                                            if (res.error) return snackbar({
                                                message: "生成邀请码失败，" + res.error,
                                                placement: 'top-end',
                                                closeable: true
                                            });
                                            refreshAuth();
                                            snackbar({
                                                message: "生成邀请码成功",
                                                placement: 'top-end',
                                                closeable: true
                                            });
                                        });
                                    }}
                                    className="rounded-md bg-orange-600 w-fit px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                >
                                    接受邀请
                                </button> :
                                <span className="text-md font-semibold text-gray-800">{Auth.guildInviteCode}</span>
                        }

                        {
                            Auth.guildInviteCode ?
                                <span className="text-xs text-gray-600">提供此邀请码，公会所有者便可邀请您加入</span> :
                                <span className="text-xs text-gray-600">生成邀请码以便公会所有者邀请您加入</span>
                        }
                    </div>
                    <div>或</div>
                    <div className="flex flex-col space-y-1 justify-center items-center">
                        <mdui-dialog
                            id="newGuildDialog"
                            close-on-esc
                            close-on-overlay-click
                        >
                            <h1 className="text-base font-semibold">创建一个公会</h1>
                            <p className="mt-1 text-xs leading-6 text-gray-600">
                                公会的名称和称号都是唯一的，成员可选择展示公会称号，公会称号拥有特殊样式。
                            </p>
                            <div className="flex flex-col space-y-2">
                                <mdui-text-field id="guildName" label="公会名称*" class="p-0 focus:ring-0 outline-0 border-0"></mdui-text-field>
                                <mdui-text-field id="guildTitle" label="公会称号*" class="p-0 focus:ring-0 outline-0 border-0"></mdui-text-field>
                            </div>

                            <div className="mt-4 flex items-center justify-end gap-x-6">
                                <button
                                    onClick={() => {
                                        const dialog = document.getElementById('newGuildDialog');
                                        dialog.open = false;
                                        const name = document.getElementById('guildName').value;
                                        const title = document.getElementById('guildTitle').value;
                                        if(!name || !title) return snackbar({
                                            message: "公会名称和称号不能为空",
                                            placement: 'top-end',
                                            closeable: true
                                        });
                                        submitNewGuild(name, title).then((res) => {
                                            if(res.error) return snackbar({
                                                message: "创建公会失败，" + res.error,
                                                placement: 'top-end',
                                                closeable: true
                                            });
                                            refreshAuth();
                                            snackbar({
                                                message: "创建公会成功",
                                                placement: 'top-end',
                                                closeable: true
                                            });
                                        });
                                    }}
                                    className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                >
                                    创建
                                </button>
                            </div>
                        </mdui-dialog>
                        <button
                            onClick={() => {
                                const dialog = document.getElementById('newGuildDialog');
                                dialog.open = true;
                            }}
                            disabled={!Auth.isVIP}
                            className={"rounded-md w-fit px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600" + (
                                !Auth.isVIP ? " bg-gray-300" : " bg-orange-600 hover:bg-orange-500"
                            )}
                        >
                            创建公会
                        </button>
                        <span className="text-xs text-gray-600">如果您拥有VIP，那便可创建一个属于您的公会</span>
                    </div>
                </div> : <GuildManage />
            }
        </div>
    );
}