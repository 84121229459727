export const passwordCheck = (password) => {
    if(password.length < 8) return false;
    if(!password.match(/[a-z]/)) return false;
    if(!password.match(/[A-Z]/)) return false;
    if(!password.match(/[0-9]/)) return false;
    return true;
}

export const testEmail = (email) => {
    const reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    return reg.test(email);
}
