import React, { useState } from "react";
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useHover,
    useFocus,
    useDismiss,
    useRole,
    useInteractions,
    FloatingPortal
} from "@floating-ui/react";

export default function Tooltip({ content, children }) {
    const [isOpen, setIsOpen] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: "top",
        // Make sure the tooltip stays on the screen
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(5),
            flip({
                fallbackAxisSideDirection: "start"
            }),
            shift()
        ]
    });

    const hover = useHover(context, { move: false });
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: "tooltip" });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
        focus,
        dismiss,
        role
    ]);

    children = React.Children.map(children, (child) => {
        return React.cloneElement(child, {
            ref: refs.setReference,
            ...getReferenceProps()
        });
    });

    return (
        <div>
            {children}
            <FloatingPortal>
                {isOpen && (
                    <div
                        className="absolute z-50 rounded-md px-2 py-1 text-sm drop-shadow-sm bg-gray-100 text-black border border-gray-300"
                        ref={refs.setFloating}
                        style={floatingStyles}
                        {...getFloatingProps()}
                    >
                        {content}
                    </div>
                )}
            </FloatingPortal>
        </div>
    );
}