import { Link } from 'react-router-dom';

export default function NotFound() {

  return (
      <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8" style={{
          minHeight: 'calc(100vh - 13.05rem)',
      }}>
          <div className="text-center">
              <p className="text-base font-semibold text-orange-600">404</p>
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">页面不存在</h1>
              <p className="mt-6 text-base leading-7 text-gray-600">您请求的页面不存在</p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                  <Link
                      to="/"
                      className="rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                  >
                      返回首页
                  </Link>
              </div>
          </div>
      </main>
  );
}
