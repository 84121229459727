import { useState, useEffect } from "react";
import { snackbar } from "mdui/functions/snackbar.js";
import { dialog } from "mdui/functions/dialog.js";
import { fetchServerList } from "../api/auth";
import Tooltip from "../components/ToolTip";

export default function Server() {
    const servers = [
        {
        id: 100,
        name: '<官方>L0香港混战服[国际][10v10][二次元模型][禁止魔法子弹]',
        ip: 'o.l0ve.lol',
        info: <>
            <p>
                控制台执行 connect o.l0ve.lol 连接服务器<br />
                官方10v10混香港混战服，大陆连接需要加速器，仅禁止魔法子弹(已部署魔法子弹检测插件)，无额外规则。<br/>
                自定义二次元模型，无任何SVS。
            </p>
        </>
    },
    {
        id: 1,
        name: '<官方>L0混战1服[10v10][二次元模型][14900K]',
        ip: 's.l0ve.lol',
        info: <>
            <p>
                控制台执行 connect s.l0ve.lol 连接服务器<br />
                官方10v10混战1服，14900K i9澎湃性能，直飞不卡脚，仅禁止魔法子弹(已部署魔法子弹检测插件)，无额外规则。<br/>
                自定义二次元模型，无任何SVS。
            </p>
        </>
    }, {
        id: 2,
        name: '<官方>L0混战2服[10v10][二次元模型]',
        ip: 's2.l0ve.lol',
        info: <>
            <p>
                控制台执行 connect s2.l0ve.lol 连接服务器<br />
                官方10v10混战2服，仅禁止魔法子弹(已部署魔法子弹检测插件)，无额外规则。<br/>
                自定义二次元模型，无任何SVS。
            </p>
        </>
    }, {
        id: 4,
        name: '<官方>L0混战3服[10v10][二次元模型][14900K]',
        ip: 's3.l0ve.lol',
        info: <>
            <p>
                控制台执行 connect s3.l0ve.lol 连接服务器<br />
                官方10v10混战3服，14900K i9澎湃性能，仅禁止魔法子弹(已部署魔法子弹检测插件)，无额外规则。<br/>
                自定义二次元模型，无任何SVS。
            </p>
        </>
    }, {
            id: 5,
            name: '<官方>L0混战4服[10v10][二次元模型]',
            ip: 's4.l0ve.lol',
            info: <>
                <p>
                    控制台执行 connect s4.l0ve.lol 连接服务器<br />
                    官方10v10混战4服，仅禁止魔法子弹(已部署魔法子弹检测插件)，无额外规则。<br/>
                    自定义二次元模型，无任何SVS。
                </p>
            </>
        }, {
            id: 8,
            name: '<官方>L0混战5服[10v10][二次元模型]',
            ip: 's5.l0ve.lol',
            info: <>
                <p>
                    控制台执行 connect s5.l0ve.lol 连接服务器<br />
                    官方10v10混战5服，仅禁止魔法子弹(已部署魔法子弹检测插件)，无额外规则。<br/>
                    自定义二次元模型，无任何SVS。
                </p>
            </>
        }, {
            id: 7,
            name: '<官方>L0混战6服[10v10][二次元模型][北部大区]',
            ip: 's6.l0ve.lol',
            info: <>
                <p>
                    控制台执行 connect s6.l0ve.lol 连接服务器<br />
                    官方10v10混战6服，仅禁止魔法子弹(已部署魔法子弹检测插件)，无额外规则。<br/>
                    自定义二次元模型，无任何SVS。
                </p>
            </>
        }, {
            id: 10,
            name: '<官方>L0混战7服[10v10][二次元模型]',
            ip: 's7.l0ve.lol',
            info: <>
                <p>
                    控制台执行 connect s7.l0ve.lol 连接服务器<br />
                    官方10v10混战7服，仅禁止魔法子弹(已部署魔法子弹检测插件)，无额外规则。<br/>
                    自定义二次元模型，无任何SVS。
                </p>
            </>
        }, {
            id: 11,
            name: '<官方>L0混战8服[10v10][二次元模型]',
            ip: 's8.l0ve.lol',
            info: <>
                <p>
                    控制台执行 connect s8.l0ve.lol 连接服务器<br />
                    官方10v10混战8服，仅禁止魔法子弹(已部署魔法子弹检测插件)，无额外规则。<br/>
                    自定义二次元模型，无任何SVS。
                </p>
            </>
        }, {
            id: 19,
            name: '<官方>L0混战9服[10v10][二次元模型]',
            ip: 's9.l0ve.lol',
            info: <>
                <p>
                    控制台执行 connect s9.l0ve.lol 连接服务器<br />
                    官方10v10混战9服，仅禁止魔法子弹(已部署魔法子弹检测插件)，无额外规则。<br/>
                    自定义二次元模型，无任何SVS。
                </p>
            </>
        }, {
            id: 20,
            name: '<官方>L0混战10服[10v10][二次元模型]',
            ip: 's10.l0ve.lol',
            info: <>
                <p>
                    控制台执行 connect s10.l0ve.lol 连接服务器<br />
                    官方10v10混战10服，仅禁止魔法子弹(已部署魔法子弹检测插件)，无额外规则。<br/>
                    自定义二次元模型，无任何SVS。
                </p>
            </>
        },
        {
            id: 101,
            name: '<官方>L0香港混战服[国际][10v10][二次元模型][禁止无扩散]',
            ip: 'o.l0ve.lol:27016',
            info: <>
                <p>
                    控制台执行 connect o.l0ve.lol:27016 连接服务器<br />
                    官方10v10国际有扩散混战，仅禁止魔法子弹(已部署魔法子弹检测插件)，测试服务器已开启强制有扩散，强开无扩散无法绕过检测，无额外规则。<br/>
                    自定义二次元模型，无任何SVS。
                </p>
            </>
        },{
        id: 3,
        name: '<官方>L0有扩散混战1服[10v10][二次元模型][禁止无扩散][14900K]',
        ip: 's.l0ve.lol:27016',
        info: <>
            <p>
                控制台执行 connect s.l0ve.lol:27016 连接服务器<br />
                官方10v10有扩散混战，14900K i9澎湃性能，仅禁止魔法子弹(已部署魔法子弹检测插件)，测试服务器已开启强制有扩散，强开无扩散无法绕过检测，无额外规则。<br/>
                自定义二次元模型，无任何SVS。
            </p>
        </>
    },{
            id: 6,
            name: '<官方>L0有扩散混战2服[10v10][二次元模型][禁止无扩散]',
            ip: 's2.l0ve.lol:27016',
            info: <>
                <p>
                    控制台执行 connect s2.l0ve.lol:27016 连接服务器<br />
                    官方10v10有扩散混战，仅禁止魔法子弹(已部署魔法子弹检测插件)，测试服务器已开启强制有扩散，强开无扩散无法绕过检测，无额外规则。<br/>
                    自定义二次元模型，无任何SVS。
                </p>
            </>
        },{
            id: 9,
            name: '<官方>L0有扩散混战3服[10v10][二次元模型][禁止无扩散][14900K]',
            ip: 's3.l0ve.lol:27016',
            info: <>
                <p>
                    控制台执行 connect s3.l0ve.lol:27016 连接服务器<br />
                    官方10v10有扩散混战，14900K i9澎湃性能，仅禁止魔法子弹(已部署魔法子弹检测插件)，测试服务器已开启强制有扩散，强开无扩散无法绕过检测，无额外规则。<br/>
                    自定义二次元模型，无任何SVS。
                </p>
            </>
        },{
            id: 9,
            name: '<官方>L0有扩散混战4服[10v10][二次元模型][禁止无扩散][北部大区]',
            ip: 's6.l0ve.lol:27016',
            info: <>
                <p>
                    控制台执行 connect s6.l0ve.lol:27016 连接服务器<br />
                    官方10v10有扩散混战，仅禁止魔法子弹(已部署魔法子弹检测插件)，测试服务器已开启强制有扩散，强开无扩散无法绕过检测，无额外规则。<br/>
                    自定义二次元模型，无任何SVS。
                </p>
            </>
        }
        ]

    const [list, setList] = useState([]);

    function initServer() {
        fetchServerList().then(result => {
            if(result.error) return snackbar({
                message: "获取服务器列表失败，" + result.error,
                placement: 'top-end',
                closeable: true
            });
            setList(result);
        });
    }

    useEffect(() => {
        initServer();
        const interval = setInterval(() => {
            initServer();
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    const playerCountBg = (countString) => {
        const count = countString.split('/');
        if(count.length < 2) return 'bg-gray-150';
        count[0] = Number(count[0]);
        count[1] = Number(count[1]);
        if(isNaN(count[0]) || isNaN(count[1])) return 'bg-gray-200/40';
        if(count[0] < 1) return 'bg-gray-200/40';
        if(count[0] < count[1]) return 'bg-green-300/40';
        return 'bg-red-300/40';
    }

    let serverCount = 0;

    function getMapUrl(map) {
        const mapIcons = [
            'ar_baggage',
            'ar_dizzy',
            'ar_lunacy',
            'ar_monastery',
            'ar_shoots',
            'coop_kasbah',
            'cs_agency',
            'cs_assault',
            'cs_insertion',
            'cs_italy',
            'cs_militia',
            'cs_office',
            'cs_workout',
            'de_abbey',
            'de_ancient',
            'de_anubis',
            'de_austria',
            'de_aztec',
            'de_bank',
            'de_biome',
            'de_breach',
            'de_cache',
            'de_canals',
            'de_cbble',
            'de_chlorine',
            'de_dust',
            'de_dust2',
            'de_inferno',
            'de_lake',
            'de_mirage',
            'de_mutiny',
            'de_nuke',
            'de_overpass',
            'de_ruby',
            'de_safehouse',
            'de_seaside',
            'de_shipped',
            'de_shortdust',
            'de_shortnuke',
            'de_stmarc',
            'de_studio',
            'de_subzero',
            'de_sugarcane',
            'de_swamp',
            'de_train',
            'de_vertigo',
            'de_zoo',
            'dz_blacksite',
            'dz_junglety',
            'dz_sirocco',
            'gd_cbble',
            'gd_rialto'
        ];
        if(mapIcons.includes(map)) {
            return `/img/maps/map_icon_${map}.svg`;
        }
        return `/img/maps/collection_icon_none.png`;
    }

    return (
        <div style={{
            minHeight: 'calc(100vh - 13.05rem)',
        }}>
            {
                list.length < 1 && <div>
                    <div className="mdui-prose">
                        <h2>服务器列表</h2>
                        <p>请在加入列出的服务器前阅读该服务器的详情信息以获悉该服务器的功能以及在服务器中游戏的规则和限制，点击服务器名称查看详情</p>
                    </div>
                    <mdui-list>
                        <mdui-collapse accordion>
                            {servers.map(server => (
                                    <mdui-collapse-item key={server.id}>
                                        <mdui-list-item slot="header" icon="storage" rounded>{server.name}</mdui-list-item>
                                        <div style={{
                                            marginLeft: '2.5rem'
                                        }} className="border rounded-2xl p-2 my-2">
                                            <div className="mdui-prose">
                                                {server.info}
                                            </div>
                                            <div className="flex flex-col items-end">
                                                <mdui-button variant="filled"
                                                             href={"steam://rungameid/730//+connect " + server.ip}
                                                             target="_blank" end-icon="start"
                                                             className="mt-2 bg-orange-500">连接到服务器
                                                </mdui-button>
                                            </div>
                                        </div>
                                    </mdui-collapse-item>
                                )
                            )}
                        </mdui-collapse>
                    </mdui-list>
                </div>
            }
            {
                list.length > 1 && <div className="flex flex-col space-y-4">
                    <span
                        className="text-3xl font-semibold bg-gradient-to-r from-orange-500 to-orange-300 text-transparent bg-clip-text">服务器列表</span>
                    {
                        list.map(group => <div key={'group-' + group.id} className="">
                            <span
                                className="text-2xl font-semibold my-4 bg-gradient-to-r from-orange-500 to-orange-300 text-transparent bg-clip-text">{group.name}</span>
                            <div className="mt-4 grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                                {
                                    group.servers.map(server => <div
                                        className="ring-1 ring-orange-200 h-16 rounded-lg flex relative select-none group overflow-hidden"
                                        style={{
                                            backgroundImage: `url(/img/bg/${(() => {
                                                const totalBgs = 16;
                                                return ++serverCount % totalBgs || totalBgs;
                                            })()}.jpg)`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center'
                                        }}
                                    >
                                        <div className="absolute z-0 w-full h-full" style={{
                                            backgroundColor: 'rgb(243 244 246 / 0.45)'
                                        }}/>
                                        <div className="absolute z-10 inset-0 text-xs flex items-start justify-end">
                                            <div className="rounded-bl-lg overflow-hidden flex">
                                                <div className="bg-gray-200/40 px-3 py-0.5">{server.region}</div>
                                                <div
                                                    className={`${playerCountBg(server.players)} py-0.5 w-12 text-center`}>{server.players}</div>
                                            </div>
                                        </div>
                                        <div className="absolute z-10 inset-0 flex items-end justify-end">
                                            <div className="relative text-orange-300">
                                                <svg width="3rem" height="3rem" stroke="currentColor" strokeWidth="2"
                                                     className="tabler-icon tabler-icon-hash -mb-3 -mr-3"
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     viewBox="0 0 24 24" fill="none" strokeLinecap="round"
                                                     strokeLinejoin="round">
                                                    <path d="M5 9l14 0"></path>
                                                    <path d="M5 15l14 0"></path>
                                                    <path d="M11 4l-4 16"></path>
                                                    <path d="M17 4l-4 16"></path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div
                                            className="absolute z-10 inset-0 flex items-end justify-end text-xl pr-3 font-bold text-orange-500 italic font-mono">
                                            {server.index}
                                        </div>
                                        <div
                                            className="h-full z-10 flex items-center px-3 relative bg-gradient-to-r to-transparent from-gray-200">
                                            <img src={getMapUrl(server.map)} alt={server.map} className="h-[40px] w-[40px]" />
                                            {
                                                /*
                                                <div className={"h-[40px] w-[40px] " + server.map}></div>
                                                 */
                                            }
                                        </div>
                                        <div className="z-10 flex flex-col justify-between text-xs px-3">
                                            <div
                                                className="bg-gray-200/40 text-center px-2 py-0.5 rounded-b text-black/80">
                                                {server.title}
                                            </div>
                                            <div className="pb-2 text-black/70">{server.map}</div>
                                        </div>
                                        <div
                                            className="absolute z-20 inset-0 items-center justify-center hidden group-hover:flex bg-white/50 backdrop-blur-[1px]">
                                            <div className="flex items-center space-x-2">
                                                <Tooltip content="复制指令">
                                                    <button onClick={() => {
                                                        navigator.clipboard.writeText(`connect ${server.ip}`);
                                                        snackbar({
                                                            message: "已复制连接指令",
                                                            placement: 'top-end',
                                                            closeable: true
                                                        });
                                                    }} className="btn hover:bg-gray-300/50">
                                                        <svg width="16" height="16" stroke="currentColor"
                                                             strokeWidth="2"
                                                             className="tabler-icon tabler-icon-copy "
                                                             xmlns="http://www.w3.org/2000/svg"
                                                             viewBox="0 0 24 24" fill="none" strokeLinecap="round"
                                                             strokeLinejoin="round">
                                                            <path
                                                                d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path>
                                                            <path
                                                                d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
                                                        </svg>
                                                    </button>
                                                </Tooltip>
                                                <Tooltip content="详情信息">
                                                    <button className="btn hover:bg-gray-300/50" onClick={() => {
                                                        dialog({
                                                            headline: "服务器详情 " + server.index,
                                                            body: `
                                                            IP: ${server.ip}<br>
                                                            指令: connect ${server.ip}<br>
                                                            地区: ${server.fullRegion || server.region}<br><br>
                                                            ${server.info || '暂无更多详情信息'}
                                                            `,
                                                            closeOnOverlayClick: true,
                                                            actions: [
                                                                {
                                                                    text: "关闭",
                                                                },
                                                                {
                                                                    text: "复制指令",
                                                                    onClick: () => {
                                                                        navigator.clipboard.writeText(`connect ${server.ip}`);
                                                                        snackbar({
                                                                            message: "已复制连接指令",
                                                                            placement: 'top-end',
                                                                            closeable: true
                                                                        });
                                                                    }
                                                                },
                                                                {
                                                                    text: "连接到服务器",
                                                                    onClick: () => {
                                                                        window.open("steam://rungameid/730//+connect " + server.ip);
                                                                        return false;
                                                                    },
                                                                }
                                                            ]
                                                        });
                                                    }}>
                                                        <svg width="16" height="16" stroke="currentColor"
                                                             strokeWidth="2"
                                                             className="tabler-icon tabler-icon-list "
                                                             xmlns="http://www.w3.org/2000/svg"
                                                             viewBox="0 0 24 24" fill="none" strokeLinecap="round"
                                                             strokeLinejoin="round">
                                                            <path d="M9 6l11 0"></path>
                                                            <path d="M9 12l11 0"></path>
                                                            <path d="M9 18l11 0"></path>
                                                            <path d="M5 6l0 .01"></path>
                                                            <path d="M5 12l0 .01"></path>
                                                            <path d="M5 18l0 .01"></path>
                                                        </svg>
                                                    </button>
                                                </Tooltip>
                                                <Tooltip content="加入游戏">
                                                    <button
                                                        onClick={() => window.open("steam://rungameid/730//+connect " + server.ip)}
                                                        className="btn hover:bg-gray-300/50">
                                                        <svg width="16" height="16" stroke="currentColor"
                                                             strokeWidth="2"
                                                             className="tabler-icon tabler-icon-brand-steam "
                                                             xmlns="http://www.w3.org/2000/svg"
                                                             viewBox="0 0 24 24" fill="none" strokeLinecap="round"
                                                             strokeLinejoin="round">
                                                            <path
                                                                d="M16.5 5a4.5 4.5 0 1 1 -.653 8.953l-4.347 3.009l0 .038a3 3 0 0 1 -2.824 3l-.176 0a3 3 0 0 1 -2.94 -2.402l-2.56 -1.098v-3.5l3.51 1.755a2.989 2.989 0 0 1 2.834 -.635l2.727 -3.818a4.5 4.5 0 0 1 4.429 -5.302z"></path>
                                                            <circle cx="16.5" cy="9.5" r="1"
                                                                    fill="currentColor"></circle>
                                                        </svg>
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>)
                                }
                            </div>
                        </div>)
                    }
                </div>
            }
        </div>
    );
}
