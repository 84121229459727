import { CheckIcon } from '@heroicons/react/20/solid';
import { submitBuyRequest } from "../api/auth";
import { getToken } from "../api/constant";
import { snackbar } from "mdui/functions/snackbar.js";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ModelShop() {

    function submitBuy(id) {
        if(!getToken()) return snackbar({
            message: "请先登录再进行购买",
            placement: 'top-end',
            closeable: true
        });
        submitBuyRequest(id).then(result => {
            if(result.error) {
                snackbar({
                    message: "购买失败，" + result.error,
                    placement: 'top-end',
                    closeable: true
                });
                return;
            }
            window.location.href = result;
        });
    }

    const packs = [
        {
            name: '[二次元]胜利女神Alice',
            id: 'model-ALS',
            main: 'HK$76',
            sub: '/ ~¥68.8',
            discountMain: 'HK$39',
            discountSub: '/ ~¥36.8',
            onClick: () => {
                submitBuy('model-ALS');
            },
            buttonText: '购买',
            description: '买二次元模型，当HVH大蛇',
            features: [<a href="/img/model/als.jpg" target="_blank" className="underline">模型预览</a>, '购买后终身可用'],
            mostPopular: false
        },{
            name: '[二次元]三月七',
            id: 'model-SYQ',
            main: 'HK$76',
            sub: '/ ~¥68.8',
            discountMain: 'HK$39',
            discountSub: '/ ~¥36.8',
            onClick: () => {
                submitBuy('model-SYQ');
            },
            buttonText: '购买',
            description: '买二次元模型，当HVH大蛇',
            features: [<a href="/img/model/syq.jpg" target="_blank" className="underline">模型预览</a>, '购买后终身可用'],
            mostPopular: false
        }
    ]

    const boxes = [
        {
            "id": "box-NewGuanghuan5",
            "name": "新光环系盲盒[5]",
            main: 'HK$78',
            sub: '/ ~¥73',
            buttonText: '购买',
            onClick: () => {
                submitBuy('box-NewGuanghuan5');
            },
            description: '买二次元模型，当HVH大蛇',
            "rewards": [
                {
                "name": "[Paid]Nachoneko",
                "modelIndex": 39
                },
                {
                "name": "mana",
                "modelIndex": 102
                },
                {
                "name": "le_malin",
                "modelIndex": 100
                },
                {
                "name": "lb",
                "modelIndex": 99
                }
            ],
        },
        {
            "id": "box-NewGuanghuan1",
            "name": "新光环系盲盒[1]",
            main: 'HK$78',
            sub: '/ ~¥73',
            buttonText: '购买',
            onClick: () => {
                submitBuy('box-NewGuanghuan1');
            },
            description: '买二次元模型，当HVH大蛇',
            "rewards": [
                {
                    "name": "zzmaya",
                    "modelIndex": 90
                },
                {
                    "name": "zome",
                    "modelIndex": 94
                },
                {
                    "name": "yuudachi",
                    "modelIndex": 79
                },
                {
                    "name": "yasina",
                    "modelIndex": 117
                }
            ],
        },
        {
            "id": "box-NewGuanghuan2",
            "name": "新光环系盲盒[2]",
            main: 'HK$78',
            sub: '/ ~¥73',
            buttonText: '购买',
            onClick: () => {
                submitBuy('box-NewGuanghuan2');
            },
            description: '买二次元模型，当HVH大蛇',
            "rewards": [
                {
                    "name": "nahida",
                    "modelIndex": 87
                },
                {
                    "name": "murasame",
                    "modelIndex": 104
                },
                {
                    "name": "nainiu",
                    "modelIndex": 115
                },
                {
                    "name": "kikyo",
                    "modelIndex": 86
                }
            ],
        },
        {
            "id": "box-NewGuanghuan3",
            "name": "新光环系盲盒[3]",
            main: 'HK$78',
            sub: '/ ~¥73',
            buttonText: '购买',
            onClick: () => {
                submitBuy('box-NewGuanghuan3');
            },
            description: '买二次元模型，当HVH大蛇',
            "rewards": [
                {
                    "name": "haku_wedding",
                    "modelIndex": 93
                },
                {
                    "name": "elaina",
                    "modelIndex": 107
                },
                {
                    "name": "bulaner",
                    "modelIndex": 110
                },
                {
                    "name": "baizhouxin",
                    "modelIndex": 82
                }
            ],
        },
        {
            "id": "box-NewGuanghuan4",
            "name": "新光环系盲盒[4]",
            main: 'HK$78',
            sub: '/ ~¥73',
            buttonText: '购买',
            onClick: () => {
                submitBuy('box-NewGuanghuan4');
            },
            description: '买二次元模型，当HVH大蛇',
            "rewards": [
                {
                    "name": "tiangongxin",
                    "modelIndex": 109
                },
                {
                    "name": "aluona",
                    "modelIndex": 81
                },
                {
                    "name": "aphrodite",
                    "modelIndex": 74
                },
                {
                    "name": "klee",
                    "modelIndex": 97
                }
            ],
        },
        {
            "id": "box-GuangHuan",
            "name": "光环系模型盲盒",
            main: 'HK$142',
            sub: '/ ~¥128',
            discountMain: 'HK$42',
            discountSub: '/ ~¥38.8',
            buttonText: '购买',
            onClick: () => {
                submitBuy('box-GuangHuan');
            },
            description: '买二次元模型，当HVH大蛇',
            "rewards": [
                {
                    "name": "[Paid]Lingmeng",
                    "modelIndex": 45
                },
                {
                    "name": "[Paid]Iochi_mari_gym",
                    "modelIndex": 62
                },
                {
                    "name": "[Paid]Dujiaoshou",
                    "modelIndex": 40
                },
                {
                    "name": "[Paid]Bachongying",
                    "modelIndex": 38
                }
            ],
        },
        {
            "id": "box-MengNiang",
            "name": "萌娘模型盲盒",
            main: 'HK$217',
            sub: '/ ~¥199',
            discountMain: 'HK$142',
            discountSub: '/ ~¥128',
            buttonText: '购买',
            onClick: () => {
                submitBuy('box-MengNiang');
            },
            description: '买二次元模型，当HVH大蛇',
            "rewards": [
                {
                    "name": "[Paid]Yui",
                    "modelIndex": 33
                },
                {
                    "name": "[Paid]Karenina",
                    "modelIndex": 34
                },
                {
                    "name": "[Paid]Heleqianshi",
                    "modelIndex": 60
                },
                {
                    "name": "[Paid]Lafei",
                    "modelIndex": 63
                }
            ],
        },
        {
            "id": "box-NiSang",
            "name": "姐姐系模型盲盒",
            main: 'HK$217',
            sub: '/ ~¥199',
            discountMain: 'HK$142',
            discountSub: '/ ~¥128',
            buttonText: '购买',
            onClick: () => {
                submitBuy('box-NiSang');
            },
            description: '买二次元模型，当HVH大蛇',
            "rewards": [
                {
                    "name": "[Paid]Haku",
                    "modelIndex": 59
                },
                {
                    "name": "[Paid]Lamiya",
                    "modelIndex": 44
                },
                {
                    "name": "[Paid]Chgh",
                    "modelIndex": 66
                },
                {
                    "name": "[Paid]Guanghuilifu",
                    "modelIndex": 58
                }
            ],
        },
        {
            "id": "box-XuanLan",
            "name": "绚烂系模型盲盒",
            main: 'HK$217',
            sub: '/ ~¥199',
            discountMain: 'HK$142',
            discountSub: '/ ~¥128',
            buttonText: '购买',
            onClick: () => {
                submitBuy('box-XuanLan');
            },
            description: '买二次元模型，当HVH大蛇',
            "rewards": [
                {
                    "name": "[Paid]Wedding_laffey",
                    "modelIndex": 72
                },
                {
                    "name": "[Paid]Qita",
                    "modelIndex": 65
                },
                {
                    "name": "[Paid]Sutera",
                    "modelIndex": 57
                },
                {
                    "name": "[Paid]Hunshaguanghui",
                    "modelIndex": 61,
                }
            ]
        }
    ];

    return (<>
        <h1 className="mt-4 text-2xl font-semibold leading-6 text-gray-900">二次元模型购买</h1>
        <div className="mt-8 flex flex-col gap-y-8">
            {
                boxes.map(box => <div className="flex flex-col md:flex-row md:items-center ring-orange-500 ring-2 rounded-3xl p-8">
                    <div className="grid grid-rows-2 md:grid-rows-none md:flex-grow grid-flow-col gap-4">
                        {
                            box.rewards.map(reward =>
                                <img src={`/img/models/${reward.name}.png`} alt={reward.name} className="md:h-56 rounded-2xl" />
                            )
                        }
                    </div>
                    <div className="mt-4 md:mt-0"><h3
                        id={box.id}
                        className={classNames(
                            box.mostPopular ? 'text-orange-600' : 'text-gray-900',
                            'text-lg font-semibold leading-8'
                        )}
                    >
                        {box.name}
                    </h3>
                        <p className="mt-4 text-sm leading-6 text-gray-600">{box.description}</p>
                        <p className="mt-6 flex items-baseline gap-x-1">
                            <span
                                className="text-4xl font-bold tracking-tight text-gray-900">{box.discountMain || box.main}</span>
                            <span
                                className="text-sm font-semibold leading-6 text-gray-600">{box.discountSub || box.sub}</span>
                        </p>
                        {
                            box.discountMain ?
                                <p className="mt-1 text-sm leading-6 text-gray-600 line-through">打折前 {box.main} {box.sub}</p> : null
                        }
                        <div
                            onClick={box.onClick}
                            aria-describedby={box.id}
                            className={classNames(
                                box.mostPopular
                                    ? 'bg-orange-600 text-white shadow-sm hover:bg-orange-500'
                                    : 'text-orange-600 ring-1 ring-inset ring-orange-200 hover:ring-orange-300',
                                'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 cursor-pointer'
                            )}
                        >
                            {box.buttonText}
                        </div>
                    </div>
                </div>)
            }
        </div>
        <div
            className="isolate mx-auto mt-8 mb-8 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3 2xl:grid-cols-4">
            {packs.map((pack) => (
                <div
                    key={pack.id}
                    className={classNames(
                        pack.mostPopular ? 'ring-2 ring-orange-600' : 'ring-1 ring-gray-200',
                        'rounded-3xl p-8'
                    )}
                >
                    <h3
                        id={pack.id}
                        className={classNames(
                            pack.mostPopular ? 'text-orange-600' : 'text-gray-900',
                            'text-lg font-semibold leading-8'
                        )}
                    >
                        {pack.name}
                    </h3>
                    <p className="mt-4 text-sm leading-6 text-gray-600">{pack.description}</p>
                    <p className="mt-6 flex items-baseline gap-x-1">
                        <span
                            className="text-4xl font-bold tracking-tight text-gray-900">{pack.discountMain || pack.main}</span>
                        <span
                            className="text-sm font-semibold leading-6 text-gray-600">{pack.discountSub || pack.sub}</span>
                    </p>
                    {
                        pack.discountMain ?
                            <p className="mt-1 text-sm leading-6 text-gray-600 line-through">打折前 {pack.main} {pack.sub}</p> : null
                    }
                    <div
                        onClick={pack.onClick}
                        aria-describedby={pack.id}
                        className={classNames(
                            pack.mostPopular
                                ? 'bg-orange-600 text-white shadow-sm hover:bg-orange-500'
                                : 'text-orange-600 ring-1 ring-inset ring-orange-200 hover:ring-orange-300',
                            'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 cursor-pointer'
                        )}
                    >
                        {pack.buttonText}
                    </div>
                    <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                        {pack.features.map((feature) => (
                            <li key={feature} className="flex gap-x-3">
                                <CheckIcon className="h-6 w-5 flex-none text-orange-600" aria-hidden="true"/>
                                {feature}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    </>)
}
