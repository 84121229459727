import { useEffect } from "react";
import { snackbar } from "mdui/functions/snackbar.js";
import issueCaptcha from "../tools/issueCaptcha";
import { submitRequestPasswordReset } from "../api/auth";
import { testEmail } from "../tools/toolkit";

export default function ForgetPassword() {

    useEffect(() => {
        document.getElementById('registerButton').addEventListener('click', register);
        return () => {
            if(document.getElementById('registerButton')) document.getElementById('registerButton').removeEventListener('click', register);
        }
    }, []);

    function register() {
        const email = document.getElementById('email').value;
        if(!email) {
            snackbar({
                message: "邮箱或密码不能为空",
                placement: 'top-end',
                closeable: true
            });
            return;
        }
        if(!testEmail(email)) {
            snackbar({
                message: "邮箱格式不正确",
                placement: 'top-end',
                closeable: true
            });
            return;
        }
        issueCaptcha().then(result => {
            submitRequestPasswordReset(email, result.token, result.type).then(result => {
                if(result.error) {
                    snackbar({
                        message: "找回失败，" + result.error,
                        placement: 'top-end',
                        closeable: true
                    });
                    return;
                }
                snackbar({
                    message: "找回邮件已发送至邮箱，请点击邮件中的链接重置密码。",
                    placement: 'top-end',
                    closeable: true
                });
            });
        }, () => {});
    }

    return (
        <>
            <div className="min-h-[85vh] flex flex-col items-center justify-center">
                <h1 className="text-2xl font-semibold my-4">找回 L0veL 账户密码</h1>
                <mdui-text-field variant="filled" id="email" icon="email" type="email" label="邮箱" class="max-w-xs mt-4 p-0 focus:ring-0 outline-0 border-0"></mdui-text-field>
                <mdui-button-icon variant="filled" id="registerButton" icon="arrow_right_alt" class=" mt-8 bg-orange-500 w-12 h-12"></mdui-button-icon>
            </div>
        </>
    )
}
