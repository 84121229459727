import { snackbar } from "mdui/functions/snackbar.js";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { submitResetPassword } from "../api/auth";

export default function ResetPassword() {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    useEffect(() => {
        document.getElementById('loginButton').addEventListener('click', login);
        return () => {
            if(document.getElementById('loginButton')) document.getElementById('loginButton').removeEventListener('click', login);
        }
    }, []);

    function login() {
        const uuid = searchParams.get('uuid');
        const email = searchParams.get('email');
        const password = document.getElementById('password').value;
        if(!email || !password) {
            snackbar({
                message: "邮箱或密码不能为空",
                placement: 'top-end',
                closeable: true
            });
            return;
        }
        submitResetPassword(email, password, uuid).then(result => {
            if(result.error) {
                snackbar({
                    message: "重置失败，" + result.error,
                    placement: 'top-end',
                    closeable: true
                });
                return;
            }
            navigate('/login');
            snackbar({
                message: "设置成功，请前往登录页登录",
                placement: 'top-end',
                closeable: true
            });
        });
    }

    return (
        <>
            <div className="min-h-[85vh] flex flex-col items-center justify-center">
                <h1 className="text-2xl font-semibold my-4">设置 L0veL 账户 密码</h1>
                <mdui-text-field variant="filled" id="email" value={searchParams.get('email')} readonly icon="email" type="email" label="邮箱" class="max-w-xs mt-4 p-0 focus:ring-0 outline-0 border-0"></mdui-text-field>
                <mdui-text-field variant="filled" id="password" icon="key" type="password" label="密码" class="max-w-xs mt-4 focus:outline-0 p-0 focus:ring-0 outline-0 border-0"></mdui-text-field>
                <mdui-button-icon variant="filled" id="loginButton" icon="arrow_right_alt" class=" mt-8 bg-orange-500 w-12 h-12"></mdui-button-icon>
            </div>
        </>
    )
}
