import { dialog } from "mdui/functions/dialog.js";
import { localDev } from "../api/constant.js";

export default function issueCaptcha() {
    return new Promise((resolve, reject) => {
        const Dialog = dialog({
            headline: "需要人机验证",
            description: "请完成人机验证以继续接下来的操作",
            body: `
                <div id="challenge"></div><br><div id="challenge1"></div><br><div id="hcaptcha"></div>
            `,
            closeOnOverlayClick: true,
            onCloseed: () => {
                document.getElementById('challenge').remove();
                document.getElementById('challenge1').remove();
                document.getElementById('hcaptcha').remove();
                reject();
            }
        });

        setTimeout(() => window.hcaptcha.render('hcaptcha', {
            sitekey: localDev ? '10000000-ffff-ffff-ffff-000000000001' : '8798104b-4b96-435f-a2f0-250709ba325d',
            callback: function (token){
                document.getElementById('challenge').remove();
                document.getElementById('challenge1').remove();
                document.getElementById('hcaptcha').remove();
                Dialog.open = false;
                resolve({
                    token: token,
                    type: 'hcaptcha'
                });
            }
        }), 100);

        setTimeout(() => window.turnstile.render('#challenge', {
            sitekey: '0x4AAAAAAABiilVDvePhRHqz',
            callback: function(token){
                document.getElementById('challenge').remove();
                document.getElementById('challenge1').remove();
                document.getElementById('hcaptcha').remove();
                Dialog.open = false;
                resolve({
                    token: token,
                    type: 'cloudflare'
                });
            }
        }), 100);

        setTimeout(() => window.grecaptcha.render('challenge1', {
            sitekey: '6LcufGwjAAAAADFyL2mjMRKR3I09BF5TvmB5PVZv',
            callback: function(token) {
                document.getElementById('challenge').remove();
                document.getElementById('challenge1').remove();
                document.getElementById('hcaptcha').remove();
                Dialog.open = false;
                resolve({
                    token: token,
                    type: 'google'
                });
            },
        }), 100);

    });
}
