import { useRef, useState, useEffect, createContext } from "react";
import {
    Routes,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";
import { alert } from "mdui/functions/alert.js";
import { snackbar } from "mdui/functions/snackbar.js";
import { AudioPlayer } from "./components/player/AudioPlayer";
import useAuth from "./hooks/useAuth";
import Home from './routes/Home';
import Server from "./routes/Server";
import Login from "./routes/Login";
import Register from "./routes/Register";
import Links from "./routes/Links";
import LeaderBoard from "./routes/LeaderBoard";
import Shop from "./routes/Shop";
import ForgetPassword from "./routes/ForgetPassword";
import ResetPassword from "./routes/ResetPassword";
import Group from "./routes/Group";
import Guild from "./routes/Guild";
import Alert from "./routes/Alert";
import ModelShop from "./routes/ModelShop";
import Verify from "./routes/Verify";
import NotFound from "./routes/NotFound";
import {useLocalStorage} from "react-use";
import {XMarkIcon} from "@heroicons/react/24/outline";

export const AuthContext = createContext(null);

export const AudioPlayerContext = createContext(null);

function App() {

    const navigate = useNavigate();

    const { isAuthed, Auth, setAuthed, setUnauthed, refreshAuth } = useAuth();

    const navigations = [
        {
            title: '首页',
            icon: 'home',
            href: '/'
        },
        {
            title: '服务器列表',
            icon: 'storage',
            href: '/servers'
        },
        {
            title: '大蛇排行榜',
            icon: 'leaderboard',
            href: '/leaderboard'
        },
        {
            title: '与我们联系',
            icon: 'email',
            href: '/group'
        },
        { divider: true },
        ...isAuthed ? [
            {
                title: '商店',
                icon: 'shopping_cart',
                href: '/shop'
            },
            {
                title: '模型商店',
                icon: 'star',
                href: '/modelshop'
            },
            {
                title: '公会',
                icon: 'groups',
                href: '/guild'
            },
            {
                title: '与游戏连接',
                icon: 'link',
                href: '/link'
            }
        ] : [
            {
                title: '登录',
                icon: 'login',
                href: '/login'
            },
            {
                title: '注册',
                icon: 'how_to_reg',
                href: '/register'
            }
        ]
    ];

    const drawerRef = useRef();
    const buttonClickedRef = useRef(false);

    const location = useLocation();

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        function onWindowResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    });

    const [isAudioPlayerShowed, setIsAudioPlayerShowed] = useState(false);

    const [closeNotice, setCloseNotice] = useLocalStorage('closeNoticeVNewModel2', false);

    return (
        <>
            {
                !closeNotice &&
                <div className="pointer-events-none fixed z-[200] inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
                    <div
                        onClick={() => navigate('/modelshop')}
                        className="cursor-pointer pointer-events-auto flex items-center justify-between gap-x-6 bg-orange-600 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
                        <p className="text-sm leading-6 text-white">
                            <strong className="font-semibold">最新更新</strong>
                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current"
                                 aria-hidden="true">
                                <circle cx={1} cy={1} r={1}/>
                            </svg>
                            庆祝五一佳节，全新的模型盲盒现已上架，VIP续费更有五折优惠，VIP音乐盒规模更已扩充，低价有好料，快点我 -{'>'} <span className="inline-block underline">查看更多</span>
                        </p>
                        <button onClick={(e) => {
                            e.stopPropagation();
                            setCloseNotice(true);
                        }} type="button" className="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]">
                            <span className="sr-only">Dismiss</span>
                            <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true"/>
                        </button>
                    </div>
                </div>
            }
            <mdui-top-app-bar scroll-behavior="elevate">
                <mdui-button-icon onClick={() => {
                    drawerRef.current.open = !drawerRef.current.open;
                    buttonClickedRef.current = !drawerRef.current.open;
                }} icon="menu"></mdui-button-icon>
                <mdui-top-app-bar-title>Bzt CS2 HVH Server</mdui-top-app-bar-title>
                <div style={{
                    flexGrow: 1
                }}></div>
                {
                    isAuthed && <div onClick={() => {
                        alert({
                            headline: "退出登录",
                            description: "确定要退出登录吗？",
                            confirmText: "确定",
                            closeOnOverlayClick: true,
                            onConfirm: () => {
                                localStorage.removeItem('sessionToken');
                                setAuthed();
                                snackbar({
                                    message: "退出登录成功",
                                    placement: 'top-end',
                                    closeable: true
                                });
                                navigate('/');
                            }
                        });

                    }}>
                        <mdui-tooltip placement="bottom" content="登出">
                            <mdui-button-icon icon="login"></mdui-button-icon>
                        </mdui-tooltip>
                    </div>
                }
            </mdui-top-app-bar>
            <mdui-navigation-drawer close-on-overlay-click ref={drawerRef}
                                    open={buttonClickedRef.current ? false : (windowSize.width >= 1024)}>
                <mdui-list>
                    <mdui-collapse accordion>
                        <div className="lg:mt-[4rem]" style={{
                            marginLeft: '0.5rem'
                        }}>
                            {navigations.map((navigation, index) => (
                                navigation.divider ? (
                                        <mdui-divider key={'main-c-' + index} class="my-2"></mdui-divider>
                                    ) :
                                    navigation.children ? (
                                        <mdui-collapse-item key={'main-c-' + index}>
                                            <mdui-list-item slot="header" icon={navigation.icon} rounded>
                                                {navigation.title}
                                                <mdui-icon slot="end-icon" name="expand_more"
                                                           className="arrow"></mdui-icon>
                                            </mdui-list-item>
                                            <div style={{marginLeft: '2.5rem'}}>
                                                {navigation.children.map((child, cindex) => (
                                                    <Link to={child.href} key={`child-c-${index}-${cindex}`}
                                                          style={{textDecoration: 'none'}}
                                                          onClick={() => {
                                                              if (windowSize.width <= 1024) {
                                                                  drawerRef.current.open = false;
                                                              }
                                                          }}
                                                          target={child.href.startsWith('http') ? '_blank' : ''}>
                                                        <mdui-list-item key={`child-c-${index}-${cindex}`} rounded
                                                                        active={location.pathname === child.href ? true : null}>
                                                            {child.title}
                                                        </mdui-list-item>
                                                    </Link>
                                                ))}
                                            </div>
                                        </mdui-collapse-item>
                                    ) : (
                                        <mdui-collapse-item key={navigation.title}>
                                            <Link to={navigation.href} slot="header"
                                                  onClick={() => {
                                                      if (windowSize.width <= 1024) {
                                                          drawerRef.current.open = false;
                                                      }
                                                  }}
                                                  target={navigation.href.startsWith('http') ? '_blank' : ''}>
                                                <mdui-list-item icon={navigation.icon} rounded
                                                                active={location.pathname === navigation.href ? true : null}>
                                                    {navigation.title}
                                                </mdui-list-item>
                                            </Link>
                                        </mdui-collapse-item>
                                    )
                            ))}
                        </div>
                    </mdui-collapse>
                </mdui-list>
            </mdui-navigation-drawer>
            <mdui-layout-main>
                <div className="px-4">
                    <AudioPlayerContext.Provider value={{isAudioPlayerShowed, setIsAudioPlayerShowed}}>
                        <AuthContext.Provider value={{isAuthed, Auth, setAuthed, setUnauthed, refreshAuth}}>
                            <Routes>
                                <Route path="/" element={<Home/>}/>
                                <Route path="/servers" element={<Server/>}/>
                                <Route path="/login" element={<Login/>}/>
                                <Route path="/register" element={<Register/>}/>
                                <Route path="/link" element={<Links setIsAudioPlayerShowed={setIsAudioPlayerShowed}/>}/>
                                <Route path="/leaderboard" element={<LeaderBoard/>}/>
                                <Route path="/shop" element={<Shop/>}/>
                                <Route path="/forgetpassword" element={<ForgetPassword/>}/>
                                <Route path="/resetpassword" element={<ResetPassword/>}/>
                                <Route path="/group" element={<Group/>}/>
                                <Route path="/guild" element={<Guild/>}/>
                                <Route path="/alert" element={<Alert/>}/>
                                <Route path="/modelshop" element={<ModelShop/>}/>
                                <Route path="/verify" element={<Verify/>}/>
                                <Route path="*" element={<NotFound/>}/>
                            </Routes>
                        </AuthContext.Provider>
                    </AudioPlayerContext.Provider>
                    <div className="mt-20 w-full border-b"></div>
                    <div className="flex flex-row space-x-1 items-center justify-center my-4 text-gray-500">
                        <span>Copyright ©&nbsp;2023 - 2024&nbsp; L0veL CS2 Server</span>
                        <img alt="buildVer" className="hidden md:block"
                             src={`https://unv-shield.librian.net/api/unv_shield?url=https://static.l0ve.lol/stitle.png&backcolor=F9BF45&txt=Build.${process.env.REACT_APP_BUILD_VER || 'Unknown'}&barradius=999&border=3`}/>
                        <img alt="prodTitle" className="hidden md:block"
                             src="https://unv-shield.librian.net/api/unv_shield?url=https://static.l0ve.lol/ptitle.png&backcolor=B28FCE&txt=Fullstack%20Web%20by%20Lynn&barradius=999&border=3"/>
                    </div>
                    <div className="md:hidden flex flex-row space-x-1 pt-1 pb-4 items-center justify-center">
                        <img alt="buildVer" className="h-8"
                             src={`https://unv-shield.librian.net/api/unv_shield?url=https://static.l0ve.lol/stitle.png&backcolor=F9BF45&txt=Build.${process.env.REACT_APP_BUILD_VER || 'Unknown'}&barradius=999&border=3`}/>
                        <img alt="prodTitle" className="h-8"
                             src="https://unv-shield.librian.net/api/unv_shield?url=https://static.l0ve.lol/ptitle.png&backcolor=B28FCE&txt=Fullstack%20Web%20by%20Lynn&barradius=999&border=3"/>
                    </div>
                </div>
            </mdui-layout-main>
            <div className={"fixed inset-x-0 z-[100] bottom-0 lg:left-112 xl:left-120" + (
                isAudioPlayerShowed ? '' : ' hidden'
            )}>
                <AudioPlayer setIsAudioPlayerShowed={setIsAudioPlayerShowed}/>
            </div>
        </>
    );
}

export default App;
