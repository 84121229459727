import { useEffect, useContext } from "react";
import { snackbar } from "mdui/functions/snackbar.js";
import issueCaptcha from "../tools/issueCaptcha";
import {Link, useNavigate} from "react-router-dom";
import { AuthContext } from "../App";
import { submitRegister } from "../api/auth";
import { passwordCheck, testEmail } from "../tools/toolkit";

export default function Register() {

    const { setAuthed } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        document.getElementById('registerButton').addEventListener('click', register);
        return () => {
            if(document.getElementById('registerButton')) document.getElementById('registerButton').removeEventListener('click', register);
        }
    }, []);

    function register(e) {
        e.preventDefault();
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
        if(!email || !password) {
            snackbar({
                message: "邮箱或密码不能为空",
                placement: 'top-end',
                closeable: true
            });
            return;
        }
        if(!passwordCheck(password)) {
            snackbar({
                message: "密码必须大于8位并同时包含数字和大小写字母",
                placement: 'top-end',
                closeable: true
            });
            return;
        }
        if(!testEmail(email)) {
            snackbar({
                message: "邮箱格式不正确",
                placement: 'top-end',
                closeable: true
            });
            return;
        }
        issueCaptcha().then(result => {
            submitRegister(email, password, result.token, result.type).then(result => {
                if(result.error) {
                    snackbar({
                        message: "注册失败，" + result.error,
                        placement: 'top-end',
                        closeable: true
                    });
                    return;
                }
                localStorage.setItem('sessionToken', result);
                setAuthed();
                navigate('/link');
                snackbar({
                    message: "注册成功",
                    placement: 'top-end',
                    closeable: true
                });
            });
        }, () => {});
    }

    return (
        <>
            <div style={{
                minHeight: 'calc(100vh - 13.05rem)',
            }}>
                <div className="flex h-full flex-1 flex-col justify-center px-6 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            注册 L0veL 账户
                        </h2>
                    </div>

                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                        <form className="space-y-6">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    邮箱
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="block bg-transparent w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <label htmlFor="password"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        密码
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        className="block bg-transparent w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    id="registerButton"
                                    className="flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                >
                                    注册
                                </button>
                            </div>
                        </form>

                        <p className="mt-10 text-center text-sm text-gray-500">
                            已经拥有 L0veL 账户？
                            <Link to="/login"
                                  className="font-semibold leading-6 text-orange-600 hover:text-orange-500">
                                立即登录
                            </Link>
                        </p>
                        <div className="mt-10">
                            <div className="relative">
                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div className="w-full border-t border-gray-200"/>
                                </div>
                                <div className="relative flex justify-center text-sm font-medium leading-6">
                                    <span className="bg-white px-6 text-gray-900">或使用以下方式继续</span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-4">
                            <a
                                href={"https://auth.l0ve.lol/api/account/oauth/neverlose/start?from=" + encodeURIComponent(window.location.origin)}
                                className="flex w-full items-center justify-center gap-1.5 rounded-md bg-black px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus-visible:ring-transparent"
                            >
                                <img alt="nlIcon" src="/img/nlIcon.jpeg" className="h-5 w-5" aria-hidden="true"/>
                                <span
                                    className="text-sm text-white font-semibold leading-6">使用 Neverlose 账户登录</span>
                            </a>
                            {
                                /*
                                <a
                                href={"https://auth.l0ve.lol/api/account/oauth/zhende/start?from=" + encodeURIComponent(window.location.origin)}
                                className="flex w-full items-center justify-center gap-1.5 rounded-md hover:brightness-105 bg-violet-600 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus-visible:ring-transparent"
                            >
                                <img alt="zhendeIcon" src="/img/zhendeicon.ico" className="h-5 w-5" aria-hidden="true"/>
                                <span
                                    className="text-sm text-white font-semibold leading-6">使用 贞德游戏论坛 账户登录</span>
                            </a>
                                */
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
